#our-brands-items{
  .slider-wrapper{
    overflow: hidden;
    padding: 100px 35px 400px 35px;
    margin: -100px -35px -500px -35px;
    @media (max-width: 820px){
      margin: 0 0 0 20px;
      padding: 0 30px 0 0;
    }
  }
  .brand-slider{
    .swiper-button-prev, .swiper-button-next{
      top: 50%;
      z-index: 5 !important;
    }
    .swiper-button-prev{
      left: -70px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAjCAYAAAEBLQH3AAAABGdBTUEAALGPC/xhBQAAApBJREFUSA2tlDuIU0EUhnNvIrIgC4KCkMJyLdfWQi1cwWwSiJAqPrrYmEo7FYJ2rpWdlY3go8wLbHy0SWOzZUQkqxZi0JAYdjHxm3DPcDOTmZWsgXDmnPPPf/4zd+YkEtEvl8sdmy2LxWJSgvM2k8lM5yJWIEFkPZHNZq/N4bQT6BULCq2Mx+PjOpbP5ze0oxY2fyztTcZwC2isrfsHhJDeCrLWdjqdBpubm2ckEKpFEATTdDrdoau1mS9ZZUul0upgMFiZO5A4QED9fv9nKpU6kTKTyi+Xy4d6vd7uaDRaa7VaNpkSpnqLi7OIFGBhW3FkxHI9HvOu1Qbrg0Y7LKHVajVst9t/yJ+mkQ/CbAEloe7OcDgcJZPJk/V6/bPEnVZfbyciSqD7hrOswgBoY1YjvG047Lf8P9mZKEKywf+rD/AcwA8f4AmAgQ+whdCxD3AHwJ4TwI24CWDiBJCY3XMfQOcQ6y8nSMo+pKxbuABh9B9BDOg/TAFStg7rN/GdFpD+wAe7KlKC0v5LJ0CXRdJRcm948OthGFYWjgvXZonzqI4wSl5DokZZl7mTrtVqX7w9ymaxlUrlcLfbreNvMAJ3sGebzeZHyf8TGfMg1el0XqGkAMl3WjrPM98WErFeMjX8uE5PsWps/WJWXICkI5tN6yTjcB9DUkHJb5RcguS9udn0rQ+AkgeTyeQuwF2IspxJ09zk8rUylNxGyRYEalZegeOFa5MrHjAKT6FkG6IQomeQXHWB94vHld2C8NGBlJnVaPc+pPeI70FcWOrMFpDqrwlpptFovDMxpq/bNBPKR+H/uWdx8ugFvIT8MiqXewFxQrWO3maN5UVIl3ubJqk5NXgh5zjTHe+ZmSSmb86zvxt5Kp96eYxJAAAAAElFTkSuQmCC) no-repeat
    }
    .swiper-button-next{
      right: -70px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAjCAYAAAEBLQH3AAAABGdBTUEAALGPC/xhBQAAAqtJREFUSA2tlT1oU1EUx/OSttAEKpGCYIODARW6lEAWF0UoQr4gQlSIKC5xkYKgkygBO6mdMpmtaHAItINJBBXUDg4uOuqmWyYFFTU0H/7uw3vfV+5LX+iDyz33nP/533PPvee8UMj9ZTKZkUOnFNls9qRpKRQKhxwQn8VoNDJC5XJ5QWEUn9LYBGkM23ShcDh8Tq3Z/HilUplVinw+v6gW/wXhHi2VSvNuQ4gNVjBEPAZ4Vz1KocjlcpfF7AhpOBxuCqX6ZOABFEANkcp+v9+Nx+MHGo3GD+XuEgy5LhaLx3q93udEIjFXr9d3pV47i1sTwZkXoUXZDJy36DmNze4QYb/iCxZpdQNm7BQYV1h/SKVSkWq1qg4qMOaC+z0yGAy+xmKxaLPZ/GN3lrJk/N3pdBwMEqBmtrumFhpB3PQNgO81dkvN8b8wXlsajQSoy2hpzJYa0DfGU0ujkQD9ZDzSmC01B/kL8IGl0UgAdwHeFmbfJAIcUpNrjorQkOrV9u3GokTgPMb7Y41COTEFAPyTCaDLNs/8tvC/YLw/MfyfCoCJj87gqNdpKzXDMD4Sz5l2u/1dG9cEg3l9lN5hessO2CSk76LR6FkK6NcEX4/Z8RbI2FEQO7STJeaXyWQyX6vVeh4vjcJBJjGU+DJHfwPpIpFup9Pp8/SCvrTr5rFkEgxpmr7xivUCpJutVusqs/PnJsHMvmQSB+kpIn1OpPOQ1bikNWmzz3sikw7kNIu8BekcdbVOpHekTcyByKQjnBeRn0AaIdJbRPpwajIb6WMIL0EoOsDyfkR2k8g2AkcmckYk2zjOEs09SO7KKPdMRsmdhqTDmP429+WduSpgiwq4ELgCSIm9Nl9Qm4UgtWn+RcnJEi/8LTkxuwa/3RPTdI0ZVz87yA1N3c/+AfQSH1AtVDlbAAAAAElFTkSuQmCC) no-repeat
    }

    @media (max-width: 820px) {
      .swiper-button-prev {
        left: -20px;
      }
      .swiper-button-next {
        right: -20px;
      }
    }

    .swiper-container{
      overflow: visible;
      position: relative;

    }
    .swiper-slide{

      transition: all .3s;
        @media (max-width: 820px){
          opacity: 1;
        }
    }
    .active-slider{
      opacity: 1;
    }
  }
}
