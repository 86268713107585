footer {
	width: 100%;
	background: #272626;

	.infos{
		display: flex;
	}

	.menuFooter {
		font-family: "PolySans-Slim";
		width: 100%;
		display: flex;
		padding-bottom: 45px;
		justify-content: space-between;

		@media (max-width: 820px){
			width: calc(100% - 60px);
			flex-direction: column;
			padding: 0 30px;
		}
	}


	.contentFooter {
		.logo {
			width: auto ;
			padding: 50px 0 20px 0;
			float: right;
			img{
				width: 40px;
				height: 40px;
			}
			@media (max-width: 820px){
				margin: 0 auto;
				float: none;
				display: block;
				text-align: center;
				img {
					width: 100px;
				}
			}
		}


		.divisor {
			width: 100%;
			box-sizing: border-box;
			height: 2px;
			background: #ffffff;
			opacity: 0.2;
			clear: both;
			@media (max-width: 820px){
				width: auto;
				margin: 0 10px;
				opacity: 1;
			}
		}
	}

	.contentFooter {
		padding: 25px 0;
	}

	.copyright {
		font-size: 14px;
		height: 14px;
		line-height: 24px;
		text-align: left;
		color: white;
		padding-bottom: 15px;
		font-family: "PolySans-Slim";
		@media (max-width: 820px){
			font-size: 10px;
			padding-top: 30px;
			text-align: center;
		}
	}
}
