@import url("https://fonts.googleapis.com/css?family=Open+Sans:100,300,300i,400,400i,600,600i,700,700i,800,800i");

@font-face {
  font-family: "PolySans-Slim";
  src: url(/fonts/PolySans-Slim.woff) format("woff"),
    url(/fonts/PolySans-Slim.woff2) format("woff2");
}

@font-face {
  font-family: 'Afrah';
  src: url('/fonts/afrah/Afrah-Regular.eot'); /* IE9 Compat Modes */
  src: url('/fonts/afrah/Afrah-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/afrah/Afrah-Regular.woff') format('woff'), /* Modern Browsers */
       url('/fonts/afrah/Afrah-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/afrah/Afrah-Regular.svg#Afrah-Regular') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'AfrahLight';
  src: url('/fonts/afrah/Afrah-Light.eot'); /* IE9 Compat Modes */
  src: url('/fonts/afrah/Afrah-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/afrah/Afrah-Light.woff') format('woff'), /* Modern Browsers */
       url('/fonts/afrah/Afrah-Light.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/afrah/Afrah-Light.svg#Afrah-Light') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

* {
  text-decoration: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "PolySans-Slim";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
}
