.social-icons {
    display: flex;
    flex-wrap: wrap;

    .social-icon {
        margin: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 20px;
        height: 20px;
    }

    .facebook {
        background-image: url('/images/buyer-page/facebook.png');
    }

    .instagram {
        background-image: url('/images/buyer-page/instagram.png');
    }

    .website {
        background-image: url('/images/buyer-page/website.png');
    }
}