@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: none; }

button {
  border: none;
  outline: none; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  font-family: "PolySans-Slim";
  background: #ffffff; }

@media (max-width: 820px) {
  html, body {
    max-width: 820px;
    width: 100%;
    overflow-x: hidden; } }

/* @media (max-width: 1366px) {
    %CENTER_COMPONENT {
        width: 90%;
        margin: 0 auto;
    }

    %CENTER_COMPONENT_SMALL {
        width: 80%;
        margin: 0 auto;
    }
} */
.variation-tooltip {
  color: #6d6d6d;
  font-weight: bold;
  box-shadow: 0px 2px 6px #99999940;
  border-radius: 5px;
  padding: 6px 10px; }
  @media (max-width: 820px) {
    .variation-tooltip {
      max-width: 50px; } }

.mainLpHkh {
  font-family: "PolySans-Slim"; }
  .mainLpHkh section.aboutHKH {
    background: #f1edea;
    padding: 80px 0px; }
    .mainLpHkh section.aboutHKH .container {
      max-width: 1120px;
      margin: 0 auto; }
      @media (max-width: 991px) {
        .mainLpHkh section.aboutHKH .container {
          padding: 0px 20px; } }
      .mainLpHkh section.aboutHKH .container .row {
        display: flex;
        align-items: center;
        gap: 30px; }
        @media (max-width: 991px) {
          .mainLpHkh section.aboutHKH .container .row {
            display: block; } }
        .mainLpHkh section.aboutHKH .container .row .col-40 {
          width: 20%;
          display: flex;
          align-items: center;
          justify-content: center; }
          @media (max-width: 991px) {
            .mainLpHkh section.aboutHKH .container .row .col-40 {
              width: 100%;
              text-align: center; } }
          .mainLpHkh section.aboutHKH .container .row .col-40 .boxTittleSection h1 {
            font-size: 28px;
            letter-spacing: 1px;
            color: #50545f;
            margin-bottom: 40px;
            text-transform: uppercase;
            position: relative; }
            .mainLpHkh section.aboutHKH .container .row .col-40 .boxTittleSection h1::before {
              content: "*";
              display: block; }
        .mainLpHkh section.aboutHKH .container .row .col-60 {
          width: 80%; }
          @media (max-width: 991px) {
            .mainLpHkh section.aboutHKH .container .row .col-60 {
              width: 100%;
              text-align: center; } }
          .mainLpHkh section.aboutHKH .container .row .col-60 .textoAbout p {
            font-size: 14px;
            letter-spacing: 1px;
            line-height: 21px;
            color: #50545f;
            margin: 15px 0px;
            font-weight: 500; }
  .mainLpHkh section.brandHkh {
    padding: 80px 0px 0px 0px; }
    .mainLpHkh section.brandHkh .boxTitle {
      margin: 20px 0px 50px 0px;
      text-align: center; }
      .mainLpHkh section.brandHkh .boxTitle h1 {
        text-transform: uppercase;
        font-size: 35px;
        color: #787878; }
    .mainLpHkh section.brandHkh .container {
      max-width: 900px;
      margin: 0 auto; }
      @media (max-width: 991px) {
        .mainLpHkh section.brandHkh .container {
          max-width: max-content;
          padding: 0px 30px; } }
    .mainLpHkh section.brandHkh .boxAllSwiper {
      position: relative; }
      .mainLpHkh section.brandHkh .boxAllSwiper .slider-wrapper {
        overflow: hidden;
        padding: 100px 35px 320px 35px;
        margin: -100px -35px -190px -35px; }
        .mainLpHkh section.brandHkh .boxAllSwiper .slider-wrapper .slider-hkh {
          width: 100%;
          overflow: visible; }
          .mainLpHkh section.brandHkh .boxAllSwiper .slider-wrapper .slider-hkh .swiper-container {
            overflow: visible;
            position: relative; }
            .mainLpHkh section.brandHkh .boxAllSwiper .slider-wrapper .slider-hkh .swiper-container .swiper-wrapper {
              margin: 0 auto; }
              .mainLpHkh section.brandHkh .boxAllSwiper .slider-wrapper .slider-hkh .swiper-container .swiper-wrapper .active-slider {
                opacity: 1; }
              .mainLpHkh section.brandHkh .boxAllSwiper .slider-wrapper .slider-hkh .swiper-container .swiper-wrapper .swiper-slide {
                transition: all .3s; }
              .mainLpHkh section.brandHkh .boxAllSwiper .slider-wrapper .slider-hkh .swiper-container .swiper-wrapper .brand {
                position: relative; }
                .mainLpHkh section.brandHkh .boxAllSwiper .slider-wrapper .slider-hkh .swiper-container .swiper-wrapper .brand .brand-image-wrapper {
                  border: 0 solid #000000;
                  background-color: #fff;
                  width: 100%;
                  height: 100%; }
                  .mainLpHkh section.brandHkh .boxAllSwiper .slider-wrapper .slider-hkh .swiper-container .swiper-wrapper .brand .brand-image-wrapper .brand-tag-container {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    z-index: 13; }
                  .mainLpHkh section.brandHkh .boxAllSwiper .slider-wrapper .slider-hkh .swiper-container .swiper-wrapper .brand .brand-image-wrapper .containerImage {
                    width: 100%;
                    height: 306px;
                    background-color: white;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat; }
                    @media (max-width: 1600px) {
                      .mainLpHkh section.brandHkh .boxAllSwiper .slider-wrapper .slider-hkh .swiper-container .swiper-wrapper .brand .brand-image-wrapper .containerImage {
                        height: 256px; } }
                    @media (max-width: 1440px) {
                      .mainLpHkh section.brandHkh .boxAllSwiper .slider-wrapper .slider-hkh .swiper-container .swiper-wrapper .brand .brand-image-wrapper .containerImage {
                        height: 228px; } }
                    @media (max-width: 1280px) {
                      .mainLpHkh section.brandHkh .boxAllSwiper .slider-wrapper .slider-hkh .swiper-container .swiper-wrapper .brand .brand-image-wrapper .containerImage {
                        height: 198px; } }
                .mainLpHkh section.brandHkh .boxAllSwiper .slider-wrapper .slider-hkh .swiper-container .swiper-wrapper .brand .brand-footer {
                  display: none;
                  transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
                  z-index: 2;
                  font-family: "PolySans-Slim";
                  flex-direction: column;
                  text-align: center;
                  margin-top: auto; }
                  .mainLpHkh section.brandHkh .boxAllSwiper .slider-wrapper .slider-hkh .swiper-container .swiper-wrapper .brand .brand-footer h2 {
                    color: #000000;
                    font-size: 17px;
                    font-weight: bold;
                    letter-spacing: 4.2px;
                    line-height: 23px;
                    width: calc(100% - 40px);
                    margin: 0 20px;
                    padding: 13px 0 16px 0; }
                  .mainLpHkh section.brandHkh .boxAllSwiper .slider-wrapper .slider-hkh .swiper-container .swiper-wrapper .brand .brand-footer .text-hover {
                    color: #787878;
                    font-family: "PolySans-Slim";
                    font-size: 14px;
                    line-height: 21px; }
                  .mainLpHkh section.brandHkh .boxAllSwiper .slider-wrapper .slider-hkh .swiper-container .swiper-wrapper .brand .brand-footer button {
                    display: flex;
                    justify-content: center;
                    margin: 0 auto; }
                  .mainLpHkh section.brandHkh .boxAllSwiper .slider-wrapper .slider-hkh .swiper-container .swiper-wrapper .brand .brand-footer .subtitle {
                    text-align: center;
                    height: 36px;
                    width: 236px;
                    color: #787878;
                    font-size: 13px;
                    font-weight: bold;
                    line-height: 18px;
                    text-align: center; }
                  .mainLpHkh section.brandHkh .boxAllSwiper .slider-wrapper .slider-hkh .swiper-container .swiper-wrapper .brand .brand-footer .brand-tags {
                    color: #71cfc9;
                    font-size: 13px;
                    font-weight: bold;
                    line-height: 18px;
                    margin: 10px 0 20px 0; }
                    .mainLpHkh section.brandHkh .boxAllSwiper .slider-wrapper .slider-hkh .swiper-container .swiper-wrapper .brand .brand-footer .brand-tags span {
                      margin-right: 5px; }
                @media (min-width: 820px) {
                  .mainLpHkh section.brandHkh .boxAllSwiper .slider-wrapper .slider-hkh .swiper-container .swiper-wrapper .brand:hover {
                    z-index: 14; }
                    .mainLpHkh section.brandHkh .boxAllSwiper .slider-wrapper .slider-hkh .swiper-container .swiper-wrapper .brand:hover .brand-footer {
                      display: flex; }
                    .mainLpHkh section.brandHkh .boxAllSwiper .slider-wrapper .slider-hkh .swiper-container .swiper-wrapper .brand:hover .brand-image-wrapper {
                      padding: 23px 30px;
                      margin: -23px -23px -23px -30px;
                      background: #fff;
                      position: absolute;
                      height: auto;
                      box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.5), 0 1px 4px 0 rgba(0, 0, 0, 0.3); }
                    .mainLpHkh section.brandHkh .boxAllSwiper .slider-wrapper .slider-hkh .swiper-container .swiper-wrapper .brand:hover .brand-image-tag {
                      display: none; } }
      .mainLpHkh section.brandHkh .boxAllSwiper .buttons .swiper-button-prev,
      .mainLpHkh section.brandHkh .boxAllSwiper .buttons .swiper-button-next {
        top: 31% !important;
        filter: grayscale(100%); }
      .mainLpHkh section.brandHkh .boxAllSwiper .buttons .swiper-button-prev {
        left: -10% !important; }
      .mainLpHkh section.brandHkh .boxAllSwiper .buttons .swiper-button-next {
        right: -10% !important; }
  .mainLpHkh section.bannerHeroLp > div {
    height: 100%; }
  .mainLpHkh section.bannerHeroLp #SecondHeader {
    height: 100%;
    margin-top: 0 !important;
    padding-top: 60px; }
  @media (min-width: 1024px) {
    .mainLpHkh section.bannerHeroLp {
      height: clamp(467.2px, calc(-16px + 47.1875vw), 890px); } }
  @media (max-width: 1023px) {
    .mainLpHkh section.bannerHeroLp {
      height: 600px; } }

footer {
  overflow: hidden; }
