.messages-block-component {
  border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
  background-color: white;
  display: flex;
  justify-content: space-between;
  margin: 0 0;
  padding: 10px 10px;
  cursor: pointer;

  &.messages-block-component--active {
    // background-color: #d4d4d4;
  }

  .flag {
    display: none;
  }

  .flag--box {
    height: 19px;
  }

  &.messages-block-component--flagged {
    background-color: #66dad1;

    .flag {
      display: inline-block;
    }

    .name, .date, .message {
      color: white!important;
    }
  }

  &:first-child {
    border-top: none;
  }

  &:last-child {
    padding-bottom: 10px;
  }

  .left {
    width: 65%;
    .name {
      font-size: 18px;
      text-transform: uppercase;
      color: #50545f;
      margin-bottom: 5px;
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 20px;
    }

    .message {
      color: #a7a9af;
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 20px;
      word-break: break-word;
    }
  }

  .dates {
    font-size: 16px;
    color: #646464;

    .date {
      display: flex;
      color: #50545f;
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 20px;
    }

    .time {
      display: flex;
    }

    .calendar {
      background-image: url("/images/messages/calendar.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }

    .clock {
      background-image: url("/images/messages/clock.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }
}
