.containerLight {
    max-width: 70%;
    margin: 2rem auto;
    color: #282626;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    h2 {
        font-size: 1.5rem;
    }

    h3 {
        font-size: 1.2rem;
    }

    .messageForm {
        display: flex;
        justify-content: center;
    }

    .actionsContainer {
        display: flex;
        justify-content: center;
        gap: 1.5rem;

        button {
            padding: 10px;
            width: auto;
            min-height: 40px !important;
            min-height: 40px !important;
            letter-spacing: 0;

            &.outline {
                background-color: #fff;
                color: rgba(40, 40, 38, 0.5);
                border: solid rgba(40, 40, 38, 0.5) 1px;
            }
        }
    }

    .brandDetailsContainer {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        margin-bottom: 1.3rem;

        .brandDetails {
            display: flex;
            justify-content: space-between;
            gap: 20px;

            display: grid;
            grid-template-columns: repeat(4, 1fr);

            .brandDetailsList {
                grid-column: span 1;
            }

            .blockInfo {
                font-size: 9px;
                color: #4a4a4a;
                margin-bottom: 1rem;

                .title {
                    margin-bottom: 0.1rem;
                    height: 13px;
                    color: #4a4a4a;
                    font-size: 9px;
                    font-style: italic;
                    font-weight: 600;
                }

                p.text {
                    font-size: 14px;
                    font-weight: 600;
                }

                svg {
                    color: black;
                    font-size: 1.2rem;
                    margin-right: 0.5rem;
                }
            }

            .brandDescription {
                grid-column: span 3;
                max-width: 872px;
                padding-right: 70px;

                p {
                    font-size: 16px;
                    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
                    font-weight: 300;
                }
            }
        }
    }

    .brandStyleContainer {
        h2 {
            margin-bottom: 1.3rem;
        }

        .imagesContainer {
            display: grid;
            grid-template-columns: 2fr 1fr 1fr;
            gap: 15px;
            width: 100%;
            align-items: start;

            .highlightImage {
                grid-column: 1 / 2;
                grid-row: 1 / 3;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .otherImages {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, 1fr);
                gap: 15px;
                grid-column: 2 / 4;
                grid-row: 1 / 3;
            }

            .otherImages img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .footerImageContainer {
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        width: 95%;

        img {
            width: 100%;
        }
    }
}

.floatingMessageButtonContainer {
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 65px;
    height: 65px;
    background-color: #272626;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    svg {
        color: #fff;
        font-size: 30px;
    }
}

@media (max-width: 1200px) {
    .container {
        max-width: 90%;
    }

    .brandDetailsContainer .brandDetails .brandDescription {
        width: 100%;
    }
}

@media (max-width: 992px) {
    .container {
        max-width: 100%;
        padding: 0 1rem;
    }

    .brandDetailsContainer .brandDetails {
        gap: 1rem;
    }
}

@media (max-width: 768px) {
    .container {
        max-width: 100%;
        padding: 0 1rem;
    }

    .brandDetailsContainer .brandDetails {
        gap: 1rem;
    }
}

@media (max-width: 576px) {
    .container {
        max-width: 100%;
        padding: 0 0.5rem;
    }

    .brandDetailsContainer {
        .brandDetails {
            display: flex !important;
            flex-direction: row !important;
            flex-wrap: wrap-reverse !important;
            gap: 1rem !important;

            .brandDetailsList {
                display: flex;
                flex-wrap: wrap;
            }

            .blockInfo {
                width: 50%;
            }

            .brandDescription {
                width: 100% !important;
            }
        }
    }

    .brandStyleContainer .imagesContainer {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr) !important;
        grid-template-rows: auto auto auto !important;
        gap: 10px !important;
    }

    .brandStyleContainer .imagesContainer .highlightImage {
        grid-column: 1 / -1 !important; /* Makes the large image span the full row */
        grid-row: 1 !important; /* Ensures it stays in the first row */
        width: 100% !important;
        height: auto !important;
    }

    .brandStyleContainer .imagesContainer .otherImages {
        display: contents !important; /* Allows the images to flow inside the grid */
    }

    .brandStyleContainer .imagesContainer .otherImages img {
        grid-column: span 1 !important;
        width: 100% !important;
        height: auto !important;
    }
}
