.boxBuyers {
  width: 960px;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto 100px auto;
}

@media (max-width: 820px) {
  .boxBuyers {
    width: calc(100% - 10px);
    padding: 0 5px;
    margin-bottom: 50px;
  }
}

.sellerView {
  .boxBuyers {
    .containerImage {
      margin: 0 auto;
    }
  }
}
