@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: none; }

button {
  border: none;
  outline: none; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  font-family: "PolySans-Slim";
  background: #ffffff; }

@media (max-width: 820px) {
  html, body {
    max-width: 820px;
    width: 100%;
    overflow-x: hidden; } }

/* @media (max-width: 1366px) {
    %CENTER_COMPONENT {
        width: 90%;
        margin: 0 auto;
    }

    %CENTER_COMPONENT_SMALL {
        width: 80%;
        margin: 0 auto;
    }
} */
.variation-tooltip {
  color: #6d6d6d;
  font-weight: bold;
  box-shadow: 0px 2px 6px #99999940;
  border-radius: 5px;
  padding: 6px 10px; }
  @media (max-width: 820px) {
    .variation-tooltip {
      max-width: 50px; } }

:local(.titleShadow) {
  background: #ffffff; }
  @media (max-width: 820px) {
    :local(.titleShadow) {
      background: transparent;
      top: -40px;
      position: relative;
      padding: 0 40px; }
      :local(.titleShadow).disable-margin {
        top: 0; }
      :local(.titleShadow) .titleGrid5 {
        min-height: auto; } }
    @media (max-width: 820px) and (max-width: 820px) {
      :local(.titleShadow) .titleGrid5 {
        display: block;
        padding: 1rem;
        text-align: center; } }
  @media (max-width: 820px) {
      :local(.titleShadow).titleShadowContainer {
        top: -20px; } }
  :local(.titleShadow) h1, :local(.titleShadow) h2, :local(.titleShadow) h3, :local(.titleShadow) h4 {
    width: 100%;
    box-sizing: border-box;
    font-size: 40px;
    font-weight: bold;
    min-height: 97px;
    position: relative;
    background: #ffffff;
    padding: 25px;
    font-family: "PolySans-Slim";
    display: flex;
    align-items: center; }
    @media (max-width: 820px) {
      :local(.titleShadow) h1, :local(.titleShadow) h2, :local(.titleShadow) h3, :local(.titleShadow) h4 {
        display: block;
        line-height: 36px;
        padding: 13px 0 0;
        width: auto;
        text-align: center;
        font-size: 26px; } }
    :local(.titleShadow) h1:before, :local(.titleShadow) h2:before, :local(.titleShadow) h3:before, :local(.titleShadow) h4:before {
      content: "";
      width: 79px;
      height: 100%;
      border-left: none;
      position: absolute;
      top: 0; }
      @media (max-width: 820px) {
        :local(.titleShadow) h1:before, :local(.titleShadow) h2:before, :local(.titleShadow) h3:before, :local(.titleShadow) h4:before {
          display: none; } }
  :local(.titleShadow).right {
    margin-right: -76px;
    margin-top: 33px;
    text-align: right; }
    :local(.titleShadow).right.disable-margin {
      margin-right: 10px; }
      :local(.titleShadow).right.disable-margin h2 {
        background-color: transparent; }
      :local(.titleShadow).right.disable-margin h2::before {
        box-shadow: none; }
    @media (max-width: 820px) {
      :local(.titleShadow).right {
        margin: 0;
        text-align: center; } }
    :local(.titleShadow).right h1, :local(.titleShadow).right h2, :local(.titleShadow).right h3, :local(.titleShadow).right h4 {
      padding: 0;
      z-index: 3;
      justify-content: flex-end; }
      @media (max-width: 820px) {
        :local(.titleShadow).right h1, :local(.titleShadow).right h2, :local(.titleShadow).right h3, :local(.titleShadow).right h4 {
          justify-content: initial; } }
    :local(.titleShadow).right h2 {
      padding: 25px 25px 25px 0px; }
    :local(.titleShadow).right h1:before, :local(.titleShadow).right h2:before, :local(.titleShadow).right h3:before, :local(.titleShadow).right h4:before {
      width: 79px;
      right: 0;
      z-index: -1;
      background: #fff;
      box-shadow: 3px 2px 2px rgba(0, 0, 0, 0.5); }
      @media (max-width: 820px) {
        :local(.titleShadow).right h1:before, :local(.titleShadow).right h2:before, :local(.titleShadow).right h3:before, :local(.titleShadow).right h4:before {
          display: none; } }
  :local(.titleShadow).left {
    margin-left: -79px;
    justify-content: left; }
    :local(.titleShadow).left.disable-margin {
      margin-left: 0px; }
      :local(.titleShadow).left.disable-margin h2 {
        background-color: transparent; }
      :local(.titleShadow).left.disable-margin h2::before {
        box-shadow: none; }
    :local(.titleShadow).left h2 {
      padding-right: 0px; }
    @media (max-width: 820px) {
      :local(.titleShadow).left {
        margin: 0px;
        justify-content: initial; } }
    :local(.titleShadow).left h1:before, :local(.titleShadow).left h2:before, :local(.titleShadow).left h3:before, :local(.titleShadow).left h4:before {
      left: 0;
      box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.5); }
