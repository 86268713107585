@import './Reset.scss';
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i&display=swap');

$BACKGROUND_ORANGE: #f68b19;
$BUTTON_DEFAULT: #71CFC9;
$FONT_COLOR: #ffffff;
$COLOR_BLUE: #1c3763;
$COLOR_WHITE: #ffffff;
$COLOR_GREEN: #39b54a;
$FONT: "PolySans-Slim";
$FONT-POLY: "PolySans-Slim";
$GREEN_LIGHT: #71CFC9;
$MARGIN: 40px auto;
$marginTopHero: 60px;
$light-gray: #50545F8F;

body {
    font-family: $FONT;
    background: #ffffff;
}

%Montserrat {
    font-family: $FONT, sans-serif;
}


%BORDER_DEBUG {
    box-sizing: border-box;
    border: 2px solid red;
}

%TITLE {
    font-family: $FONT;
    color: $COLOR_WHITE;
    font-size: 22px;
    font-weight: 600;
}

%BUTTON_ORANGE {
    background: $BACKGROUND_ORANGE;
    padding: 10px 40px;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    border-radius: 6px;
    border: 1px solid $BACKGROUND_ORANGE
}

%BUTTON_BLUE {
    width: 113px;
    height: 31px;
    line-height: 33px;
    color: #ffffff;
    background: #71CFC9;
    font-size: 11px;
    text-transform: uppercase;
    border-radius: 8px;
    font-weight: 400;
    text-align: center;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
}

%BUTTON_BLACK {
    font-weight: normal;
    height: 40px;
    width: 130px;
    font-family: $FONT-POLY;
    text-transform: uppercase;
    color: #FFF;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    background-color: #272626;
    border-radius: 30px;
    float: left;
    margin: 0 20px;
}

%LINK {
    font-size: 12px;
    color: #000000;
    padding-bottom: 3px;
    font-weight: bold;
    border-bottom: 1px solid #444444;
}

%LINK_UNDERLINE {
    font-size: 11px;
    font-weight: 600;
    color: #000000;
    border-bottom: 2px solid #000;
    font-family: $FONT;
    height: 15px;
    line-height: 15px;

    &:hover {
        color: #D8D8D8;
        border-bottom: 2px solid #D8D8D8;
    }
}

%CENTER_COMPONENT {
    width: 1180px;
    margin: 0 auto;

    @media (max-width: 1200px){
        width: 100%;
    }

    @media (max-width: 820px) {
        width: auto;
    }
}

%CENTER_COMPONENT_SMALL {
    width: 50%;
    margin: 0 auto;
}

%SPACE_ELEMENTS {
    height: 70px;
}



@media (max-width: 820px) {

    html, body {
        max-width: 820px;
        width: 100%;
        overflow-x: hidden;
    }
}


/* @media (max-width: 1366px) {
    %CENTER_COMPONENT {
        width: 90%;
        margin: 0 auto;
    }

    %CENTER_COMPONENT_SMALL {
        width: 80%;
        margin: 0 auto;
    }
} */
.variation-tooltip{
    color: #6d6d6d;
    font-weight: bold;
    box-shadow: 0px 2px 6px #99999940;
    border-radius: 5px;
    padding: 6px 10px;
    @media (max-width: 820px) {
        max-width: 50px;
    }
}
