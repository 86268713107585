#forgot-password-modal {
  h2 {
    color: #000;
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
    margin: 20px 0 30px 0;
  }

  p {
    color: #989b9d;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  .submit {
    text-align: center;
  }
  .error {
    color: red;
    font-size: 11px;
  }
  .send-button {
    font-weight: normal;
    height: 40px;
    width: 130px;
    font-family: "PolySans-Slim";
    text-transform: uppercase;
    color: #FFF;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    background-color: #272626;
    border-radius: 30px;
    float: left;
    margin-right: 20px;
  }

  .form-item {
    margin-bottom: 20px;
    #email,
    .input {
      width: calc(100% - 10px);
      height: 25px;
      padding: 5px;
      margin-top: 10px;
      border: 1px solid #cccccc;
      border-radius: 4px;
    }
    &.submit {
      text-align: right;
    }
  }

  p.fieldError {
    text-transform: uppercase;
    font-size: 10px;
    color: #d95c5c;
    text-align: left;
    margin-bottom: 15px;
    font-weight: 700;
    font-family: "Polysans-Slim", Helvetica, Arial, sans-serif;
  }
}
.lightBox.forgot-password {
  .containerLightBox {
    height: auto;
  }
  .bgLightBox {
    opacity: 0;
  }
}
