.connection-component {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    .loading-button {
        display: flex;
        margin: 0;
    }

    button {
      margin: 10px;
      width: auto;
      padding: 0 15px;
      &.button--pending {
        background-color: transparent;
        border: 2px solid #c6c6c6;
        color: #c6c6c6;
      }
    }

    .request {
      width: 230px;
    }
}