@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: none; }

button {
  border: none;
  outline: none; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  font-family: "PolySans-Slim";
  background: #ffffff; }

@media (max-width: 820px) {
  html, body {
    max-width: 820px;
    width: 100%;
    overflow-x: hidden; } }

/* @media (max-width: 1366px) {
    %CENTER_COMPONENT {
        width: 90%;
        margin: 0 auto;
    }

    %CENTER_COMPONENT_SMALL {
        width: 80%;
        margin: 0 auto;
    }
} */
.variation-tooltip {
  color: #6d6d6d;
  font-weight: bold;
  box-shadow: 0px 2px 6px #99999940;
  border-radius: 5px;
  padding: 6px 10px; }
  @media (max-width: 820px) {
    .variation-tooltip {
      max-width: 50px; } }

.grid5 {
  width: 90%;
  max-width: 1320px;
  height: 475px;
  margin-bottom: 30px;
  display: flex !important;
  justify-content: center; }
  .grid5 .leftGrid {
    width: 50%;
    position: relative;
    display: flex; }
    .grid5 .leftGrid .heartIconContainer {
      position: absolute;
      top: 0;
      cursor: pointer; }
    .grid5 .leftGrid .heart-icon {
      width: 2rem;
      padding-top: 0.5rem;
      padding-left: 0.5rem; }
    .grid5 .leftGrid .brand-image {
      width: 100%;
      max-height: 475px;
      object-fit: cover;
      border-radius: 5px; }
    .grid5 .leftGrid .brandImageContainer {
      width: 100%;
      max-height: 475px; }
  .grid5 .rightGrid {
    width: 50%;
    max-height: 475px;
    box-sizing: border-box;
    float: right;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .grid5 .rightGrid .link {
      text-decoration: none;
      color: inherit; }
    .grid5 .rightGrid .infoComponent {
      width: 100%;
      min-height: 60px; }
    .grid5 .rightGrid .containerInfoStatic {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      padding-left: 70px; }
      .grid5 .rightGrid .containerInfoStatic .alignLeft {
        display: flex;
        gap: 2rem; }
    .grid5 .rightGrid .blockInfo {
      font-size: 9px;
      color: #4a4a4a; }
      .grid5 .rightGrid .blockInfo .title {
        height: 13px;
        color: #4a4a4a;
        font-size: 9px;
        font-style: italic;
        font-weight: 600; }
      .grid5 .rightGrid .blockInfo p.text {
        color: #000000;
        font-size: 14px;
        font-weight: 600;
        margin-top: 5px; }
      .grid5 .rightGrid .blockInfo svg {
        color: black;
        font-size: 1rem;
        margin-top: 5px; }
      .grid5 .rightGrid .blockInfo .socialIcons {
        display: flex;
        gap: 10px; }
    .grid5 .rightGrid .actionsContainer {
      padding-left: 74px;
      padding-bottom: 10px;
      margin-top: 1rem;
      display: flex;
      gap: 0.5rem; }
  .grid5 .containerLink {
    margin: 21px 0 16px 0; }
    .grid5 .containerLink a {
      margin: 0; }
  .grid5 .containerButtonText {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 74px;
    padding-top: 20px; }
  .grid5 p.textgrid5 {
    width: 550px;
    line-height: 26px;
    font-size: 14px;
    color: #4a4a4a; }

@media (max-width: 1200px) {
  .grid5 {
    max-height: 400px; }
    .grid5 .leftGrid .brand-image {
      width: 100%;
      height: 400px;
      object-fit: cover; }
    .grid5 .leftGrid .brandImageContainer {
      width: 100%;
      max-height: 400px; }
    .grid5 .rightGrid .containerInfoStatic {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 1rem; } }

@media (max-width: 820px) {
  .grid5 {
    height: 475px;
    max-height: 475px;
    flex-direction: column;
    text-align: center; }
    .grid5 .leftGrid {
      width: 100%; }
      .grid5 .leftGrid .heart-icon {
        width: 2.5rem;
        padding-top: 0.5rem;
        padding-left: 0.5rem; }
      .grid5 .leftGrid .brand-image {
        width: 100%;
        height: 475px;
        object-fit: cover; }
      .grid5 .leftGrid .brandImageContainer {
        width: 100%;
        height: 475px; }
    .grid5 .rightGrid {
      width: 100%; }
      .grid5 .rightGrid .containerInfoStatic,
      .grid5 .rightGrid .containerButtonText,
      .grid5 .rightGrid .actionsContainer {
        display: none; } }
