.buttonWithBgProfile{
    box-sizing: border-box;
    width: 181px;
    display: flex;
    height: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: #000;
    margin-left: 2.8rem;

    & + button{
        margin-left: 20px;
    }

    @media (max-width: 768px){    
        font-size: 12px;
        width: 140px;
    }

    .loading-element{
        all: unset;
        color: #fff;
        transform: translateY(10px);
        & > svg{
            height: 20px;
        }
    }
}