@import "../../../../shared/style/Base";

.grid4 {
	width: 100%;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	.button {
		width: fit-content;
		padding: 0 15px;
	}


	.contentLinkButton {
		width: calc(100% - 90px);
		margin-top: 10px;

		.linkShowCase {
			display: inline-block;
			margin: 0 0 15px 0;
			@extend %LINK_UNDERLINE;
		}

		.buttonShowCase {
			display: block;
			@extend %BUTTON_BLUE;
		}
	}

	.leftGrid {
		max-width: 499px;
		width: 100%;
		text-align: right;

		@media(max-width: 820px){
			max-width: 400px;
		}

		img {
			width: 100%;
			height: 332px;
		}

		&.portrait{
			max-width: 300px;

			img{
				max-width: 300px;
				width: auto;
				height: auto;
			}
		}
	}

	.rightGrid {
		width: calc(100% - 523px);
		box-sizing: border-box;

		img {
			width: 100%;
			height: 332px;
		}

		&.portrait{
			width: calc(100% - 325px);
		}

		.infoComponent {
			width: 100%;
			min-height: 60px;
		}

		.containerInfoStatic {
			display: flex;
			align-items: baseline;
			padding-left: 70px;

			.blockInfo {
				font-size: 9px;
			    color: #4a4a4a;

				.title {
					height: 13px;
					color: #4A4A4A;
					font-size: 9px;
					font-style: italic;
					font-weight: 600;
				}

				p.text {
					color: #000000;
					font-size: 14px;
					font-weight: 600;
					margin-top: 5px;
				}

				&.brand {
					flex-basis: 140px;
				}

				&.year {
					flex-basis: 150px;
					margin-left: 82px;
				}
			}


		}

		.containerLink {
			margin: 0 0 16px 0;
			cursor: pointer;

			a {
				margin: 0;
			}
		}

		.containerButtonText {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			padding-left : 74px;
		}

		p.textGrid1 {
		    width: 468px;
			line-height: 26px;
			font-size: 14px;
			color: #4A4A4A;
			margin-bottom: 21px;
		}

		.left {
			margin-left: -100px;
			&.disable-margin {
				margin-left: 50px;
				h2 {
					background-color: transparent;
				}
				h2::before {
					box-shadow: none;
				}
			}
		}
	}
}

.grid4-mobile {
	@media (max-width: 820px){
		padding: 0 20px 20px;


		.main-image {
			// height: 250px;
			overflow: hidden;

			#showcase {
				overflow: hidden;
			}
			img {
				width: 75%;
				position: relative;
				// right: -110px;
				// height: 250px;

				float: right
			}
		}

		.grid-title {
			position: relative;
			right: 50px;
			margin-bottom: 0;
			text-align: right;
			top: -50px;

			&.disable-margin {
				top: 0;
			}

			>div {
				top: 0;
			}

			h2 {
				text-align: right;
				padding-right: 24px;
				font-size: 26px;
				line-height: 36px;
				padding-left: 20px;
			}
		}

		.grid4-mobile-content {
			line-height: 26px;
			font-size: 14px;
			color: #4A4A4A;
			padding: 0 30px;

			.content-buttons {
				.read-more-link {
					margin-top: 30px;
					margin-bottom: 40px;
					text-align: center;
				}

				.link-show-case {
					font-size: 12px;
					font-weight: 600;
					line-height: 22px;
					padding: 0 0 3px 0;
					border: none;
					color: #000;

					span {
						border-bottom: 1px solid $GREEN_LIGHT;
					}
				}

				.see-products-button {
					text-align: center;
					margin-bottom: 40px;
				}
			}
		}
	}
}

.top29 {
	margin-top: 29px;
}
