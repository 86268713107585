.__unit{

			text-transform: uppercase;
			font-family: "PolySans-Slim";
			font-size: 14px;
    font-weight: 400;
		    color: #d8d8d8;
				text-align: right;
    display: block;
    padding: 13px 0px;
		span{
			cursor: pointer;
		}
			.active{
				color: #fbfbfb;
			}
}
.sizeChart-table-wrapper{
	background: #FFF;
	padding: 20px;
}
.sizeChart-table{
	font-size: 14px;
	font-family: "PolySans-Slim";
	line-height: 20px;
		border-collapse: separate;
		border-spacing: 1px;
		width:100%;
		tr td {
			border-top: 3px solid #fff;
			border-bottom: 3px solid #fff;
		}



	.__sizes {
		width: 100%;
		text-transform: uppercase;
		color: #fff;
		background: #6d6d6d;
		transition: background-color 0.05s linear;

		td {
			padding: 5px 15px;
		}
	}

	.__measurements {
		padding: 5px 15px;
		color: #fff;
		background: #6d6d6d;
		transition: background-color 0.05s linear;
	}
	.__values {
		padding: 5px 15px;
	}
}
.sizeChart-table tr:hover td {
	border-top:3px solid #71cfc9 !important;
	border-bottom:3px solid #71cfc9 !important;
}
