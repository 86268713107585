@import "../../shared/style/Base";
.cartEmpity {
  height: calc(100vh - 100px);
  width: 100%;
  font-weight: 300;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 30px;
  z-index: 3;

  h1 {
    margin: 20px 0;
  }

  .linkBrands {
    padding: 10px 15px;
    background-color: transparent;
    text-transform: uppercase;
    border: 2px solid #999;
    color: #999;
    border-radius: 10px;
  }
}
@keyframes form-success {
  0% {
    background-color: rgba(0, 140, 0, 0.4);
  }
  100% {
    background-color: transparent;
  }
}

#cart {
  display: grid;
  grid-template-columns: 30% 70%;
  padding-left: 15%;
  padding-right: 15%;
  margin-top: 40px;
  background-color: #f1edea;

  @media (max-width: 1200px) {
    padding-left: 5%;
    padding-right: 5%;
  }

  .title-collection {
    padding: 9px 9px 16px 9px;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: -0.25px;
    color: #6d6d6d;
  }

  .change-pricelist {
    margin-bottom: 30px;
  }

  .autofill-one-unit {
    display: inline-block;
    margin-bottom: 30px !important;
    button {
      width: 175px;
      height: 35px;
    }
  }

  .title {
    grid-column: span 2;
    font-size: 36px;
    letter-spacing: 1px;
    line-height: 30px;
    color: #50545f;
    margin-bottom: 30px;
    margin-top: 50px;
  }

  @media (max-width: 820px) {
    display: flex;
    padding: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0;

    .title {
      margin-top: 80px;
    }
  }

  b {
    font-weight: bold;
  }
  .color-name {
    font-size: 12px;
    color: #6d6d6d;
  }
  .send-order-btn {
    width: auto;
    padding: 0 10px;

    &.add-success {
      animation: form-success 2s;
      background: black;
    }
  }

  .cart-list-mobile {
    position: fixed;
    z-index: 9999;
    height: calc(100vh - 56px);
    width: 100%;
    top: 100%;
    background-color: #f6f6f6;
    padding-top: 40px;
    transition: 500ms;

    .btn-close-cartlist {
      height: 30px;
      width: 30px;
      border-radius: 15px;
      background-color: #000000;
      color: #fbfbfb;
      position: absolute;
      top: 5px;
      right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .cart-list-opened {
    top: 56px;
    transition: 500ms;
  }

  .see-all {
    height: 80px;
    width: 100%;
    margin-top: 20px;
    background: #000000;
    color: #fbfbfb;
    font-size: 16px;
    border: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
  }

  .product-brands {
    background-color: white;
    height: fit-content;
    max-height: 650px;
    overflow-y: auto;

    @media (max-width: 820px) {
      width: 100%;
    }

    .cart-box-title {
      width: 100%;
      height: 50px;
      line-height: 50px;
      color: #666;
      text-align: center;
      font-size: 18px;
      font-weight: 100;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      text-transform: uppercase;
    }

    .load-more-container {
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 20px 0;

      .load-more {
        width: 160px;
        margin: 0 auto;
        border: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        border: 2px solid #636363;
        letter-spacing: 2px;
        color: #636363;
      }
    }

    > div:first-child .brand {
      border-top: 0;
    }

    .brand {
      color: #666;
      display: flex;
      flex-direction: column;
      border-top: 1px solid #dbdbdb;
      cursor: pointer;
      padding: 5px 8px 5px 13px;
      margin: 5px;
      box-sizing: border-box;
      position: relative;
      &.active {
        color: white;

        button.brand-item-statistic--button {
          color: white;
          background: #66dad1;
          border: 1px solid white;
          font-weight: 800;

          .cart-status {
            font-weight: normal;
          }
        }

        span {
          color: white;
          z-index: 2;
        }

        span.cart-status-open:after {
          background-color: white;
        }

        &:after {
          content: "\A";
          position: absolute;
          width: 100%;
          height: 100%;
          padding: 5px;
          margin: -5px -18px;
          background-color: #66dad1;
          z-index: 1;
        }
      }

      .cart-status {
        font-size: 12px;
        letter-spacing: 2px;
        line-height: 20px;
        color: #50545f;
        text-transform: uppercase;
        &::after {
          content: "";
          display: inline-block;
          width: 16px;
          height: 16px;
          margin-left: 5px;
          margin-right: 10px;
          border-radius: 50%;
          float: right;
        }
        &.cart-status-open {
          &::after {
            background-color: #71cfc9;
          }
        }
        &.cart-status-closed {
          &::after {
            background-color: #d95c5c;
            box-shadow: #d95c5c 0 0 2px 1px;
          }
        }
      }

      .brand-name {
        font-size: 21px;
        letter-spacing: 1px;
        line-height: 20px;
        color: #50545f;
        margin-top: 5px;
        text-transform: uppercase;
      }

      .brand-item-statistic {
        color: #a7a9af;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 20px;
        margin-bottom: 15px;

        &.brand-item-statistic--multiple-collections {
          display: grid;
          gap: 0.5rem;
        }

        .brand-item-statistic--button {
          &:nth-child(1) {
            margin: 15px 0 0 0;
          }

          color: #66dad1;
          font-size: 10px;
          width: 100%;
          height: fit-content;
          margin: 5px 0;
          padding: 5px 5px;
          background: white;
          border: 1px solid #66dad1;
          border-radius: 8px;
          flex-direction: column;

          > .cart-status {
            text-align: center;
            color: #666;
            display: block;
            font-size: 7px;
            margin: 5px 0 0 0;
            padding: 0;
            line-height: normal;
            &::after {
              display: none;
            }
          }

          &.brand-item-statistic--button--active {
            background-color: white;
            color: #66dad1;
          }
        }

        strong {
          font-weight: bold;
        }
      }

      .brand-item-date {
        color: #6d6d6d;
        font-size: 12px;
      }
    }
  }

  .product-items {
    margin-left: 10px;
    position: relative;
    padding: 20px 9px 48px 9px;
    background-color: white;

    @media (max-width: 820px) {
      margin: 0;
      padding: 0;
      border: 0;
      width: 100%;
    }

    .feedback-collection-validation {
      display: flex;
      flex-direction: column;
      gap: 6px;
      
      p {
        display: flex;
        align-items: flex-start;
        gap: 9.67px;
        font-size: 16px;
        font-family: "PolySans-Slim", sans-serif;

        img {
          width: 20px;
          height: 20px;
        }
      }

      .error-collection-validation {
        color: #F9635D;
        text-transform: uppercase;
      }

      .success-collection-validation {
        color: #50545F;
      }
    }

    .box-error {
      background-color: white;
      position: absolute;
      text-transform: uppercase;
      right: -26px;
      bottom: 45px;
      margin-bottom: 20px;
      text-align: center;
      padding: 15px;
      max-width: 180px;
      font-size: 12px;
      box-shadow: 0 0 30px #999;
      line-height: 17.14px;
      z-index: 2;
      color: #666;

      @media (max-width: 500px) {
        position: initial;
        box-shadow: none;
        max-width: initial;
        border: 1px solid red;
        background: rgba(232, 53, 53, 0.05);
      }

      &::after {
        content: "";
        position: absolute;
        border-style: solid;
        border-width: 10px 10px 0;
        border-color: #fff transparent;
        display: block;
        width: 0;
        z-index: 1;
        bottom: -10px;
        left: 100px;
      }

      h1 {
        font-weight: bold;
        font-size: 14px;
        color: red;
        margin-bottom: 10px;
      }
    }

    .product-details-box {
      padding: 9px 9px 16px 9px;
      border-radius: 5px;
    }
    .view-size-chart {
      cursor: pointer;
      text-align: left;
    }
    .order-tools {
      display: grid;
      text-align: center;
      justify-content: right;
      margin-bottom: 20px;
      grid-template-columns: 50% 30% 20%;

      @media (max-width: 500px) {
        grid-template-columns: 50% 30% 20%;
      }

      > div {
        border-width: 0 1px 1px 1px;
        background: 0;
        outline: 0;
        line-height: 33px;
        font-size: 12px;
        position: relative;
        text-transform: uppercase;
        color: #333;
        text-align: right;
        margin-right: 20px;
      }

      .view-size-chart {
        text-align: left;
        margin-left: 20px;
      }
      .no-sizechart {
        border-left: 0px;
        border-bottom: 0px;
      }
    }

    .header {
      display: flex;
      align-items: center;
      text-align: left;
      justify-content: space-between;

      @media (max-width: 820px) {
        justify-content: space-between;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        flex-direction: column;
      }

      margin-bottom: 40px;

      .title-container {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        margin-left: 10px;

        @media (max-width: 820px) {
          justify-content: center;
          margin-left: 0;
        }
      }

      .buttons-container {
        display: flex;
        gap: 1rem;

        .send-order-btn {
          font-size: 10px;
          padding: 0 16px;
        }
      }

      h2 {
        position: relative;
        text-align: left;
        text-transform: uppercase;
        font-size: 36px;
        color: #666666;
        margin: 20px 0;
      }

      button {
        text-transform: uppercase;
        height: 40px;
        margin: 0;

        @media (min-width: 820px) {
          height: 30px;
        }

        &.disabled {
          background: rgb(225, 197, 197);
        }
      }
    }

    .order-details {
      display: grid;
      grid-template-columns: 18% 82%;
      width: 100%;

      .product-image {
        border: 1px solid #c0c0c0;
        width: 70px;
        height: 93px;
        // overflow: hidden;
        background: #fff;
        position: relative;

        img {
          width: 100%;
          @media (max-width: 820px) {
            height: 100%;
          }
        }

        .remove-cart {
          color: #a0a0a0;
          cursor: pointer;
          font-size: 10px;
          position: absolute;
          bottom: -20px;
          left: 0;
        }
      }

      .product-info {
        display: flex;
        justify-content: space-between;

        @media (max-width: 1200px) {
          flex-direction: column;
          align-items: flex-start;
        }

        .product-description {
          margin: 10px 10px 0 17px;
          line-height: 25px;

          textarea {
            resize: none;
            display: none;
            width: 280px;
            border: 1px solid #eee;
            padding: 6px 8px;
            margin-top: 5px;
            background-color: #fdfdfd;

            &.visible {
              display: block;
            }

            @media (max-width: 820px) {
              width: 100%;
            }
          }

          .product-note {
            margin-top: 15px;

            > div {
              color: #a0a0a0;
              cursor: pointer;
              font-size: 13px;
            }

            b {
              text-decoration: underline;
              cursor: pointer;
              user-select: none;
            }

            .total,
            .quantity {
              position: relative;
            }
            .quantity {
              margin-top: 5px;
            }
            .total {
              .box-error {
                bottom: 30px;
                right: 0px;
              }
            }
            .quantity {
              .box-error {
                right: -85px;
                bottom: -107px;
                :after {
                  top: -10px;
                  border-width: 0px 10px 10px;
                  bottom: auto;
                }
              }
            }
          }

          .product-name {
            word-break: break-word;
            @extend %Montserrat;
          }

          .product-name,
          .product-reference {
            text-transform: uppercase;
            font-size: 24px;
            font-weight: 400;
            letter-spacing: -0.25px;
            color: #6d6d6d;
            cursor: pointer;
          }

          .remove-cart {
            color: #a0a0a0;
            cursor: pointer;
            font-size: 13px;

            @media (max-width: 820px) {
              font-size: 14px;
            }
          }

          .product-reference {
            font-size: 18px;

            @media (max-width: 500px) {
              font-size: 11px;
            }
          }
        }

        .product-prices {
          font-size: 12px;
          text-transform: uppercase;
          margin: 13px 10px 0 0;
          text-align: right;
          line-height: 22px;
          color: #6d6d6d;

          @media (max-width: 1200px) {
            margin: 10px 0 0 17px;
            text-align: left;
          }
        }
      }
    }

    .product-details {
      padding: 0 0 0 9px;
      margin-top: 25px;

      .total-units {
        font-size: 12px;
        color: #6d6d6d;
        position: absolute;
        right: 28px;
      }

      .product-color {
        margin-bottom: 30px;
        display: flex;
        align-items: center;

        .color-box {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          background-size: cover;
          border: 1px solid #ccc;
          position: relative;

          .colorListNoTexture {
            position: absolute;
            display: block;
            top: 0;
            border-left: 20px solid transparent;
            border-bottom: 20px solid transparent;
          }
        }
        &:first-child {
          margin-left: 0;
        }
      }
    }

    .product-size {
      display: flex;
      flex-wrap: wrap;
      // margin-right: 60px;
      .size {
        border: 1px solid #d8d8d8;
        border-radius: 3px;
        text-align: center;
        padding: 6px 4px;
        height: 53px;
        width: 0;
        margin-right: 10px;
        min-width: 60px;
        min-height: 65px;
        margin-bottom: 10px;
        position: relative;

        &.blink {
          .bgSize {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            left: 0;
            top: 0;
            opacity: 1;
            transition: 150ms;
            background: rgb(113, 207, 201);
          }
        }

        .size-name {
          margin: 0 0 4px 0;
          font-weight: 700;
          font-size: 12px;
          position: relative;
          z-index: 2;
        }

        .size-quantity {
          display: flex;
          align-items: center;
          margin-top: 10px;
          justify-content: space-between;
          position: relative;
          z-index: 2;

          div {
            cursor: pointer;
          }

          input {
            width: 28px;
            text-align: center;
            border-radius: 3px;
            border: 1px solid #e0e4e6;
            height: 28px;
          }
        }
        ._stock_info {
          background-color: #ededed;
          position: absolute;
          top: -23px;
          right: 1px;
          text-align: center;
          padding: 1px;
          /* box-shadow: 0px 0px 30px #999; */
          /* line-height: 17.14px; */
          color: #666;
          font-size: 9px;
        }
        ._cicle {
          position: absolute;
          bottom: -17px;
          right: 6px;
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          float: right;
          color: #fff;
          background-color: #71cfc9;
        }
        .limit-alert {
          background-color: white;
          position: absolute;
          top: -100px;
          text-align: center;
          padding: 15px;
          min-width: 210px;
          box-shadow: 0px 0px 30px #999;
          line-height: 17.14px;
          color: #666;
          top: -75px;
          left: -85px;

          &::after {
            content: "";
            position: absolute;
            border-style: solid;
            border-width: 10px 10px 0;
            border-color: #fff transparent;
            display: block;
            width: 0;
            z-index: 1;
            bottom: -10px;
            left: 100px;
          }
        }
        .hidden {
          display: none;
        }
      }
    }
  }

  .order-resume {
    display: grid;
    grid-template-columns: 50% 50%;
    font-size: 12px;
    padding: 16px 20px;
    color: #6d6d6d;

    @media (max-width: 820px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 14px;
    }

    .left {
      @media (max-width: 500px) {
        margin-bottom: 40px;
        font-weight: bold;
      }
    }

    .left,
    .right {
      @media (max-width: 820px) {
        width: 100%;
      }
    }

    .highlight {
      text-transform: uppercase;
      color: #71cfc9;
      font-weight: 700;
    }
    .highlight-order {
      a {
        color: #71cfc9;
      }
    }

    textarea {
      resize: none;
      display: block;
      width: 280px;
      border: 1px solid #eee;
      padding: 6px 8px;
      margin-top: 5px;
      background-color: #fdfdfd;

      @media (max-width: 820px) {
        width: 100%;
      }
    }

    .order-note {
      margin-top: 15px;
      .total,
      .quantity {
        position: relative;
      }
      .quantity {
        margin-top: 5px;
      }
      .total {
        .box-error {
          bottom: 30px;
          right: 0px;
        }
      }
      .quantity {
        .box-error {
          right: -85px;
          bottom: -107px;
          :after {
            top: -10px;
            border-width: 0px 10px 10px;
            bottom: auto;
          }
        }
      }
    }

    b {
      font-weight: bold;
    }

    > div {
      line-height: 20px;
    }

    .right {
      text-align: right;
    }
  }

  .send-box {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

    @media (min-width: 820px) {
      flex-direction: row;
    }

    text-align: center;

    button {
      width: 220px;
      height: 42px;

      &.disabled {
        background: rgb(225, 197, 197);
      }
    }
    span {
      display: block;
      margin: 6px;
      font-size: 12px;
      text-align: center;
      cursor: pointer;
      color: #c6c6c6;
      @media (max-width: 820px) {
        margin-top: 20px;
      }
    }
  }
}
textarea:focus,
input:focus {
  outline: none !important;
}

.uppercase {
  text-transform: uppercase;
}

.list-error-order {
  margin-top: 0.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-bottom: red;

  li {
    color: #6d7284;
    font-size: 14px;
    p {
      color: red;
      display: inline-block;
      font-weight: bold;
    }
  }
}
