.connections-block-component {
  display: flex;
  justify-content: space-between;
  margin-left: 15px;
  padding-bottom: 30px;

  .left {
    display: flex;
    flex-direction: column;
    align-items: flex-start!important;

    .title {
      font-size: 16px;
      color: #646464;
      text-transform: uppercase;
      margin-left: 5px;
    }

    .buttons {
      display: flex;
      justify-content: flex-start;
      .button {
        margin: 0 5px;
        width: 109px;
        height: 31px;
        border-radius: 15px;
        background-color: #646464;
      }

      .deny {
        background-color: transparent;
        border: 2px solid #646464;
        color: #646464;
      }
    }
  }

  .right {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dates {
    font-size: 16px;
    color: #646464;
    margin-top: 10px;

    .date {
      display: flex;
      align-items: center;
      color: #9f9f9f;
      height: 30px;
    }

    .time {
      display: flex;
      align-items: center;
      height: 30px;
    }

    .calendar {
      background-image: url("/images/messages/calendar.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }

    .clock {
      background-image: url("/images/messages/clock.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }
}
