#messages-view-component {
  background-color: #f1edea;

  #messages {
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media (min-width: 820px) {
      justify-content: center;
      flex-direction: row;

      #messages-sidebar {
        width: 40%;
      }

      #messages-chat-component {
        width: 60%;
      }
    }
  }

  #messages-filter-container {
    display: flex;
    justify-content: center;
  }
}
