
.loading {
	width: 100%;
	min-height: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	padding-top: 50px;
	min-height: calc(100vh - 278px);
	img {
		margin: 0 auto;
	}

	.containerLoading {
		text-align: center;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.containerLogo {
		width: 100%;
		font-size: 18px;
		color: #cfcfcf;
		font-weight: 500;
		margin-top: 20px;
		font-family: 'PolySans-Slim';
		margin: 30px;
	}

	.load {
		-webkit-animation-name: spin;
		-webkit-animation-duration: 10000ms;
		-webkit-animation-iteration-count: infinite;
		-webkit-animation-timing-function: linear;
		-moz-animation-name: spin;
		-moz-animation-duration: 10000ms;
		-moz-animation-iteration-count: infinite;
		-moz-animation-timing-function: linear;
		-ms-animation-name: spin;
		-ms-animation-duration: 10000ms;
		-ms-animation-iteration-count: infinite;
		-ms-animation-timing-function: linear;
		-o-transition: rotate(-3600deg);
		animation-name: spin;
		animation-duration: 1000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}
}

@-moz-keyframes spin {
	from { -moz-transform: rotate(0deg); }
	to { -moz-transform: rotate(-360deg); }
}
@-webkit-keyframes spin {
	from { -webkit-transform: rotate(0deg); }
	to { -webkit-transform: rotate(-360deg); }
}
@keyframes spin {
	from {transform:rotate(0deg);}
	to {transform:rotate(-360deg);}
}
