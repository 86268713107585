@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: none; }

button {
  border: none;
  outline: none; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  font-family: "PolySans-Slim";
  background: #ffffff; }

@media (max-width: 820px) {
  html, body {
    max-width: 820px;
    width: 100%;
    overflow-x: hidden; } }

/* @media (max-width: 1366px) {
    %CENTER_COMPONENT {
        width: 90%;
        margin: 0 auto;
    }

    %CENTER_COMPONENT_SMALL {
        width: 80%;
        margin: 0 auto;
    }
} */
.variation-tooltip {
  color: #6d6d6d;
  font-weight: bold;
  box-shadow: 0px 2px 6px #99999940;
  border-radius: 5px;
  padding: 6px 10px; }
  @media (max-width: 820px) {
    .variation-tooltip {
      max-width: 50px; } }

.register-acount {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  padding: 3rem; }
  .register-acount--container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 1190px;
    width: 100%;
    height: 100%;
    padding: 0rem;
    font-family: "PolySans-Slim"; }
    .register-acount--container__topContent {
      display: flex;
      justify-content: center;
      position: relative;
      padding: 0rem;
      gap: 4rem;
      width: 100%;
      height: 100%; }
      @media (max-width: 1000px) {
        .register-acount--container__topContent {
          flex-direction: column;
          align-items: center; } }
      .register-acount--container__topContent__img {
        width: auto;
        max-width: 40%;
        height: 100%;
        max-height: 650px; }
        @media (max-width: 1000px) {
          .register-acount--container__topContent__img {
            max-width: 90%; } }
      .register-acount--container__topContent__left {
        display: flex;
        width: 50%;
        height: 100%; }
        @media (max-width: 1000px) {
          .register-acount--container__topContent__left {
            width: 100%;
            flex-direction: column;
            align-items: center; } }
        .register-acount--container__topContent__left--unlockText {
          position: absolute;
          background: white;
          padding: 1.5rem;
          left: 35%;
          top: 2%;
          font-size: 42px;
          max-width: 50%;
          vertical-align: middle; }
          .register-acount--container__topContent__left--unlockText__noImg {
            position: absolute;
            background: white;
            padding: 1rem;
            left: 50%;
            top: -5%;
            transform: translate(-50%);
            font-size: 42px;
            max-width: 50%;
            text-align: center;
            vertical-align: middle; }
            @media (max-width: 1000px) {
              .register-acount--container__topContent__left--unlockText__noImg {
                position: relative;
                left: inherit;
                max-width: 100%;
                transform: translate(0%); } }
          @media (max-width: 1200px) {
            .register-acount--container__topContent__left--unlockText {
              font-size: 32px; } }
          @media (max-width: 1000px) {
            .register-acount--container__topContent__left--unlockText {
              position: relative;
              width: fit-content;
              max-width: 100%;
              left: inherit;
              top: inherit;
              height: fit-content;
              margin-top: -2rem;
              text-align: center; } }
          .register-acount--container__topContent__left--unlockText::before {
            background: transparent;
            content: '';
            width: 10%;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            box-shadow: -4px 4px 3px rgba(0, 0, 0, 0.3); }
            @media (max-width: 1000px) {
              .register-acount--container__topContent__left--unlockText::before {
                opacity: 0; } }
          .register-acount--container__topContent__left--unlockText span {
            all: unset;
            margin-top: auto;
            margin-bottom: auto; }
            @media (max-width: 1000px) {
              .register-acount--container__topContent__left--unlockText span {
                width: 100%; } }
        .register-acount--container__topContent__left--infoData {
          display: flex;
          flex-direction: column;
          margin-top: auto;
          align-items: center;
          height: fit-content;
          margin-top: 35%;
          font-size: 26px; }
          @media (max-width: 1000px) {
            .register-acount--container__topContent__left--infoData {
              margin-top: 10%;
              text-align: justify; } }
          .register-acount--container__topContent__left--infoData__btn {
            width: fit-content !important;
            padding: 1rem !important;
            border: 1px solid #444444;
            transition: 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275); }
            .register-acount--container__topContent__left--infoData__btn:hover {
              background: white;
              border: 1px solid #444444;
              color: #444444; }
            .register-acount--container__topContent__left--infoData__btn__registered {
              width: fit-content !important;
              padding: 1rem !important;
              background: white;
              color: #444444;
              border: 1px solid #444444;
              transition: 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275); }
              .register-acount--container__topContent__left--infoData__btn__registered:hover {
                background: #444444;
                color: white; }
          .register-acount--container__topContent__left--infoData__create {
            display: flex;
            width: 100%;
            margin-top: 7rem;
            justify-content: center;
            gap: 1rem; }
            @media (max-width: 1000px) {
              .register-acount--container__topContent__left--infoData__create {
                flex-direction: column;
                align-items: center; } }
            .register-acount--container__topContent__left--infoData__create input {
              border: inherit;
              border-bottom: 2px solid #44444426;
              width: 60%;
              font-size: 18px; }
              @media (max-width: 1000px) {
                .register-acount--container__topContent__left--infoData__create input {
                  width: 70%;
                  text-align: center; } }
              .register-acount--container__topContent__left--infoData__create input::placeholder {
                font-size: 18px; }
                @media (max-width: 1000px) {
                  .register-acount--container__topContent__left--infoData__create input::placeholder {
                    text-align: center; } }
          .register-acount--container__topContent__left--infoData__bar {
            display: flex;
            content: '';
            background: #444444;
            height: 1px;
            width: 60%;
            margin-top: 3rem; }
          .register-acount--container__topContent__left--infoData__registered {
            display: flex;
            flex-direction: column;
            width: fit-content;
            justify-content: center;
            align-items: center;
            font-size: 22px;
            gap: 1rem;
            margin-top: 2rem; }
    .register-acount--container__bottomContent {
      background: #f3f3f3;
      padding: 1rem 3rem;
      width: fit-content;
      border-radius: 5rem;
      margin-top: 5rem; }
      .register-acount--container__bottomContent a {
        border-bottom: 2px solid #444444;
        cursor: pointer; }
        .register-acount--container__bottomContent a:hover {
          color: #919191;
          border-bottom: 2px solid #919191; }
    .register-acount--container__back {
      width: 90%;
      max-width: 1200px;
      margin-top: 3rem;
      color: #444444; }
      .register-acount--container__back span {
        border-bottom: 2px solid #444444; }
        .register-acount--container__back span:hover {
          color: #919191;
          border-bottom: 2px solid #919191; }

.error-email {
  font-size: 16px;
  margin-top: 0.5rem;
  color: red; }
