.SortByFilter {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin: 20px 0;

	.titleSortBy {
		font-size: 12px;
		color: #9C9B9B;
		margin-right: 10px;
		font-weight: normal;
	}

	.selectFilter {
		width: 194px;
		height: 25px;
		outline: none;
		color: #9B9B9B;
		background: white;
		border: 1px solid #9C9B9B;
		border-radius: 5px;

		option {
			font-size: 12px;
			color: #9B9B9B;
		}
	}

	@media (max-width: 820px) {
		justify-content: flex-start;
		padding-left: 20px;
	}
}