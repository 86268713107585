@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: none; }

button {
  border: none;
  outline: none; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  font-family: "PolySans-Slim";
  background: #ffffff; }

@media (max-width: 820px) {
  html, body {
    max-width: 820px;
    width: 100%;
    overflow-x: hidden; } }

/* @media (max-width: 1366px) {
    %CENTER_COMPONENT {
        width: 90%;
        margin: 0 auto;
    }

    %CENTER_COMPONENT_SMALL {
        width: 80%;
        margin: 0 auto;
    }
} */
.variation-tooltip {
  color: #6d6d6d;
  font-weight: bold;
  box-shadow: 0px 2px 6px #99999940;
  border-radius: 5px;
  padding: 6px 10px; }
  @media (max-width: 820px) {
    .variation-tooltip {
      max-width: 50px; } }

#contentReadMore {
  display: flex;
  align-items: flex-start;
  color: #5E5F5E; }
  #contentReadMore p {
    font-size: 11px;
    color: #5E5F5E;
    opacity: 0.5; }
  #contentReadMore ul {
    display: flex; }
    #contentReadMore ul li {
      margin: 0 10px;
      font-size: 12px;
      padding-bottom: 3px;
      color: #5E5F5E;
      border-bottom: 2px solid #71CFC9; }
      #contentReadMore ul li a {
        display: inline-block;
        text-decoration: none;
        font-size: 12px;
        color: #5E5F5E; }
      #contentReadMore ul li:first-child {
        margin-left: 15px; }

@media (max-width: 820px) {
  #contentReadMore {
    margin-top: 18px; }
    #contentReadMore p {
      font-size: 12px;
      white-space: nowrap;
      margin-right: 10px;
      font-weight: bold; }
    #contentReadMore ul li {
      margin: 0 10px 0 0;
      font-weight: bold; }
      #contentReadMore ul li:first-child {
        margin-left: 0; } }
