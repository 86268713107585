.container-form-company {
  display: flex;
  flex-direction: column;
  margin-top: 140px;
  max-width: 954px;
  padding-bottom: 138px;
  animation: on 0.5s ease-in-out;

  @media (max-width: 1200px) {
    margin-top: 100px;
  }

  @media (max-width: 960px) {
    margin-top: 70px;
    align-items: center;
  }

  .box-inputs__box-img-name {
    display: flex;
    width: fit-content;
    width: 100%;

    & > label {
      position: relative;

      & > button {
        all: unset;
        width: fit-content;
        padding: 0.2rem;
        position: absolute;
        right: 25px;
        top: 0;
        z-index: 10;
        background-color: #fff;
        border: 1px solid #000;
        border-radius: 4px;
        cursor: pointer;

        &:nth-child(2) {
          left: -11px;

          & > img {
            width: 15px;
          }
        }

        & > img {
          width: 20px;
        }
      }
    }

    @media (max-width: 1200px) {
      justify-content: center;
      flex-wrap: wrap;

      & > div {
        width: 432px !important;
        margin-top: 1.4rem;
      }
    }
  }

  .box-inputs__box-img-name__img {
    margin-right: 40px;
    position: relative;
    cursor: pointer;

    @media (max-width: 960px) {
      margin-bottom: 20px;
    }

    & > img {
      width: 150px;
      height: fit-content;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  button {
    margin-left: 0px;
  }

  @keyframes on {
    0% {
      opacity: 0;
    }

    60% {
      opacity: 0.6;
    }

    100% {
      opacity: 1;
    }
  }

  @media (max-width: 1200px) {
    width: 90%;
  }
}

.box-inputs__box-img-name__send-image {
  display: flex;
  width: 232px;
  height: 87px;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 6px 0px;
  flex-wrap: wrap;
  border-radius: 30px;
  background: #e6e6e6;
  cursor: pointer;
  margin-right: 40px;

  span {
    color: #000;
    text-align: center;
    font-family: PolySans-Slim;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;

    &:nth-child(2) {
      color: #767676;
      font-size: 14px;
      line-height: 23px;
    }
  }
}

.container-form-company__box-inputs {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 27px 90px;

  @media (max-width: 1200px) {
    width: 90%;
    justify-content: center;
  }
}

.container-form-company__box-textarea {
  width: 100%;
  text-align: start;
  position: relative;

  & > textarea {
    width: 100%;
    height: 120px;
    border-radius: 5px;
    border: 1px solid #d4d4d4;
    resize: none;
    padding: 10px 15px;
  }

  @media (max-width: 1200px) {
    width: 432px;
  }
}

.container-form__personal-btns {
  margin-top: 27px;
}

.container-form-company__countries {
  display: flex;
  flex-direction: column;
}

.box-input-profile__validation {
  font-size: 14px;
  margin-top: 5px;
  color: red;
  position: absolute;
  bottom: -18px;
  left: 0;
}

.box-input-location_validation {
  font-size: 14px;
  margin-top: 5px;
  color: red;
}

.container-form-company__message-request {
  width: fit-content;
  margin: 1rem 0 0 0;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    margin-left: 4px;
    height: 19px;
  }
}

#picture-input {
  all: unset;
  display: none;
}
