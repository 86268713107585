#messages-chat-component {
  max-width: 653px;

  .title {
    font-size: 60px;
    margin-left: 15px;
    margin-top: 60px;
    margin-bottom: 30px;
    text-transform: uppercase;

    @media (max-width: 820px) {
      margin-left: 0;
    }
  }

  .hiddenInput {
    display: none;
  }

  .message-text-area-container {
    position: relative;
    height: 210px;
    background-color: white;
    border-radius: 5px;
    padding: 40px 40px 0 40px;
    margin-top: 40px;
    margin-bottom: 40px;
    max-width: 653px;
    display: flex;

    textarea {
      border: none;
      resize: none;
      font-size: 18px;
      font-family: "PolySans-Slim", sans-serif;
      width: 100%;
      height: 180px;
    }

    .attach {
      width: 20px;
      height: 20px;
      padding: 10px;
      margin-left: -15px;
      margin-right: 15px;
      background-image: url("/images/messages/attach.png");
      background-repeat: no-repeat;
      background-size: auto;
      background-position: center;
      background-position-x: 13px;
      cursor: pointer;
    }

    .send {
      background-color: #9b9b9b;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      padding: 10px;
      background-image: url("/images/messages/send.png");
      background-repeat: no-repeat;
      background-size: auto;
      background-position: center;
      background-position-x: 13px;
      cursor: pointer;

      &.send--loading {
        background-image: none;
      }
    }

    .input-anexo {
      position: absolute;
      bottom: 10px;
      font-weight: 600;
      
      .close {
        margin-right: 20px;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }

  .messages-chat-block-container {
    max-height: 600px;
    overflow-y: auto;
  }
}
