@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: none; }

button {
  border: none;
  outline: none; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  font-family: "PolySans-Slim";
  background: #ffffff; }

@media (max-width: 820px) {
  html, body {
    max-width: 820px;
    width: 100%;
    overflow-x: hidden; } }

/* @media (max-width: 1366px) {
    %CENTER_COMPONENT {
        width: 90%;
        margin: 0 auto;
    }

    %CENTER_COMPONENT_SMALL {
        width: 80%;
        margin: 0 auto;
    }
} */
.variation-tooltip {
  color: #6d6d6d;
  font-weight: bold;
  box-shadow: 0px 2px 6px #99999940;
  border-radius: 5px;
  padding: 6px 10px; }
  @media (max-width: 820px) {
    .variation-tooltip {
      max-width: 50px; } }

#SecondHeader {
  margin-top: 60px;
  position: relative;
  background: white;
  background: -moz-linear-gradient(top, white 0%, white 96%, #ededed 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, white), color-stop(96%, white), color-stop(100%, #ededed));
  background: -webkit-linear-gradient(top, white 0%, white 96%, #ededed 100%);
  background: -o-linear-gradient(top, white 0%, white 96%, #ededed 100%);
  background: -ms-linear-gradient(top, white 0%, white 96%, #ededed 100%);
  background: linear-gradient(to bottom, white 0%, white 96%, #ededed 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ededed', GradientType=0 );
  box-sizing: border-box;
  display: flex;
  height: 720px;
  display: flex;
  align-items: center; }
  @media (max-width: 1360px) {
    #SecondHeader {
      height: 680px; } }
  @media (max-width: 1200px) {
    #SecondHeader {
      height: 630px; } }
  @media (max-width: 1024px) {
    #SecondHeader {
      height: 500px; } }
  @media (max-width: 820px) {
    #SecondHeader {
      margin-top: 58px; } }
  #SecondHeader .texture2 {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0px -45px 27px -28px rgba(0, 0, 0, 0.15); }
  #SecondHeader .logoAndText {
    width: 570px;
    z-index: 7;
    margin-left: 250px; }
    @media (min-width: 1650px) {
      #SecondHeader .logoAndText {
        margin-left: 100px; } }
    @media (max-width: 1650px) {
      #SecondHeader .logoAndText {
        margin-left: 200px; } }
    @media (max-width: 1480px) {
      #SecondHeader .logoAndText {
        margin-left: 160px; } }
    @media (max-width: 1360px) {
      #SecondHeader .logoAndText {
        margin-left: 130px; } }
    @media (max-width: 1200px) {
      #SecondHeader .logoAndText {
        margin-left: 100px; } }
    @media (max-width: 820px) {
      #SecondHeader .logoAndText {
        margin-left: 80px; } }
    @media (max-width: 600px) {
      #SecondHeader .logoAndText {
        margin-left: 30px; } }
    @media (max-width: 376px) {
      #SecondHeader .logoAndText {
        width: 100%;
        margin-left: 15px;
        margin-right: 15px; }
        #SecondHeader .logoAndText h1 {
          width: 50%;
          margin-bottom: 36px; } }
    #SecondHeader .logoAndText img {
      margin-bottom: 30px;
      width: 420px; }
      @media (max-width: 1680px) {
        #SecondHeader .logoAndText img {
          width: 400px; } }
      @media (max-width: 1550px) {
        #SecondHeader .logoAndText img {
          width: 365px; } }
      @media (max-width: 1366px) {
        #SecondHeader .logoAndText img {
          width: 315px; } }
      @media (max-width: 1200px) {
        #SecondHeader .logoAndText img {
          width: 270px; } }
      @media (max-width: 1100px) {
        #SecondHeader .logoAndText img {
          width: 250px; } }
      @media (max-width: 820px) {
        #SecondHeader .logoAndText img {
          width: 130px; } }
    #SecondHeader .logoAndText p {
      width: 568px;
      color: #324751;
      font-family: "PolySans-Slim";
      font-weight: 300;
      letter-spacing: -0.04px;
      line-height: 43px;
      text-shadow: 0 2px 4px 0 #FBFBFB;
      font-size: 32px; }
      @media (max-width: 1680px) {
        #SecondHeader .logoAndText p {
          font-size: 28px;
          width: 475px; } }
      @media (max-width: 1550px) {
        #SecondHeader .logoAndText p {
          font-size: 26px;
          width: 445px; } }
      @media (max-width: 1366px) {
        #SecondHeader .logoAndText p {
          font-size: 24px;
          width: 420px; } }
      @media (max-width: 1200px) {
        #SecondHeader .logoAndText p {
          font-size: 22px;
          width: 390px; } }
      @media (max-width: 1100px) {
        #SecondHeader .logoAndText p {
          font-size: 20px;
          width: 375px; } }
      @media (max-width: 820px) {
        #SecondHeader .logoAndText p {
          font-size: 12px;
          width: 135px;
          line-height: 16px; } }
    #SecondHeader .logoAndText .buttons {
      margin-top: 45px;
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: flex-start; }
      #SecondHeader .logoAndText .buttons .link {
        font-size: 14px;
        font-weight: normal;
        font-family: "PolySans-Slim";
        text-transform: uppercase;
        height: 40px;
        width: 130px;
        color: #fff;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #272626;
        border-radius: 30px; }
      @media (max-width: 1680px) {
        #SecondHeader .logoAndText .buttons {
          margin-top: 40px; }
          #SecondHeader .logoAndText .buttons .link {
            width: 180px;
            height: 55px; } }
      @media (max-width: 1366px) {
        #SecondHeader .logoAndText .buttons {
          margin-top: 35px; }
          #SecondHeader .logoAndText .buttons .link {
            width: 160px;
            height: 50px; } }
      @media (max-width: 1200px) {
        #SecondHeader .logoAndText .buttons {
          margin-top: 30px; }
          #SecondHeader .logoAndText .buttons .link {
            width: 140px;
            height: 45px; } }
      @media (max-width: 1000px) {
        #SecondHeader .logoAndText .buttons .link {
          display: none; }
          #SecondHeader .logoAndText .buttons .link:first-child {
            display: flex; } }
      @media (max-width: 820px) {
        #SecondHeader .logoAndText .buttons .link {
          font-size: 12px;
          width: 130px;
          height: 40px; } }
      @media (max-width: 600px) {
        #SecondHeader .logoAndText .buttons .link {
          width: 126px;
          height: 38px; } }
    @media (max-width: 820px) {
      #SecondHeader .logoAndText .hide-buttons {
        display: none; } }
  #SecondHeader .headerImages {
    width: 50%;
    height: 100%;
    z-index: 6;
    display: flex;
    align-items: center;
    overflow: hidden; }
    #SecondHeader .headerImages .image {
      width: 780px;
      height: 100%;
      z-index: 5;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start; }
      @media (max-width: 1366px) {
        #SecondHeader .headerImages .image {
          width: 580px; } }
      #SecondHeader .headerImages .image img {
        max-height: 480px;
        max-width: 620px;
        box-shadow: 35px 25px 30px 1px rgba(0, 0, 0, 0.17);
        position: relative; }
        @media (max-width: 1920px) {
          #SecondHeader .headerImages .image img {
            max-height: 480px;
            max-width: 620px; } }
        @media (max-width: 1680px) {
          #SecondHeader .headerImages .image img {
            max-height: 480px;
            max-width: 620px; } }
        @media (max-width: 1366px) {
          #SecondHeader .headerImages .image img {
            max-width: 520px; } }
        @media (max-width: 1200px) {
          #SecondHeader .headerImages .image img {
            max-width: 620px; } }
    #SecondHeader .headerImages .trees {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      width: 500px;
      height: 100%;
      z-index: -1;
      overflow: hidden; }
      #SecondHeader .headerImages .trees .tree-one {
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 20%;
        left: 75px;
        z-index: 3;
        background-size: cover;
        background-repeat: no-repeat; }
        @media (max-width: 820px) {
          #SecondHeader .headerImages .trees .tree-one {
            height: 290px;
            left: 50px;
            bottom: auto;
            top: -43px;
            background-repeat: no-repeat;
            background-size: auto 250px;
            background-position: right; } }
      #SecondHeader .headerImages .trees .tree-two {
        width: 90%;
        height: 60%;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        bottom: -5%;
        left: 150px;
        z-index: 2; }
        @media (max-width: 820px) {
          #SecondHeader .headerImages .trees .tree-two {
            background-position: right;
            background-size: auto 160px;
            width: 300px;
            height: 160px;
            bottom: auto;
            top: 161px;
            left: 74px; } }
  #SecondHeader .secondHeaderImages {
    width: 60%;
    height: 100%;
    z-index: 3;
    position: relative;
    right: 0; }
    #SecondHeader .secondHeaderImages .mask-img {
      display: block;
      background-repeat: no-repeat;
      position: relative;
      max-width: 100%;
      height: 100%;
      width: 100%;
      background-attachment: fixed;
      background-size: 60%;
      background-position: right 80px; }
    #SecondHeader .secondHeaderImages .object-mask {
      /* mask-image: url(./palmeira.svg);
            mask-repeat: no-repeat;
            mask-size: 89%;
            mask-position: right;*/
      position: relative;
      right: -90px;
      top: -70px; }
      @media (max-width: 1840px) {
        #SecondHeader .secondHeaderImages .object-mask {
          mask-size: 95%;
          top: -73px; } }
      @media (max-width: 1770px) {
        #SecondHeader .secondHeaderImages .object-mask {
          mask-size: 97%;
          right: -60px;
          top: -80px; } }
      @media (max-width: 1710px) {
        #SecondHeader .secondHeaderImages .object-mask {
          mask-size: 92%;
          right: -60px;
          top: -50px; } }
      @media (max-width: 1600px) {
        #SecondHeader .secondHeaderImages .object-mask {
          mask-size: 93%;
          right: -60px;
          top: -40px; } }
      @media (max-width: 1550px) {
        #SecondHeader .secondHeaderImages .object-mask {
          mask-size: 95%;
          right: -60px;
          top: -70px; } }
      @media (max-width: 1480px) {
        #SecondHeader .secondHeaderImages .object-mask {
          right: -60px;
          top: -80px; } }
      @media (max-width: 1400px) {
        #SecondHeader .secondHeaderImages .object-mask {
          mask-size: 98%;
          right: -60px;
          top: -87px; } }
      @media (max-width: 1366px) {
        #SecondHeader .secondHeaderImages .object-mask {
          mask-size: 95%;
          right: -60px;
          top: -40px; } }
      @media (max-width: 1310px) {
        #SecondHeader .secondHeaderImages .object-mask {
          mask-size: 96%;
          right: -60px;
          top: -45px; } }
      @media (max-width: 1260px) {
        #SecondHeader .secondHeaderImages .object-mask {
          mask-size: 98%;
          right: -60px;
          top: -60px; } }
      @media (max-width: 1210px) {
        #SecondHeader .secondHeaderImages .object-mask {
          mask-size: 97%;
          right: -50px;
          top: -45px; } }
      @media (max-width: 1165px) {
        #SecondHeader .secondHeaderImages .object-mask {
          mask-size: 100%;
          right: -50px;
          top: -50px; } }
      @media (max-width: 1100px) {
        #SecondHeader .secondHeaderImages .object-mask {
          mask-size: 100%;
          right: -40px;
          top: -75px; } }
      @media (max-width: 1024px) {
        #SecondHeader .secondHeaderImages .object-mask {
          mask-size: 100%;
          right: -40px;
          top: -60px; } }
