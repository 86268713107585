@import "../../../presentation/shared/style/Base";

:root {
  scroll-behavior: smooth;
}

.centerComponent {
  @extend %CENTER_COMPONENT;
  position: relative;


  &.margin {
    margin: $MARGIN;
  }

  &.mgBottom {
    margin: 40px auto 20px auto;
  }

  &.contentGrid3 {
    width: 1057px;
    box-sizing: border-box;
    @media (max-width: 820px) {
      width: 100%;
    }
  }

  &.contentGrid4history {
    width: 1056px;
  }

  &.slider-history {
    margin-bottom: 70px;
  }

  .contentGrid2History {
    width: 897px;
    display: -webkit-flex;
    display: flex;
    margin-left: 62px;

    &.marginHistory {
      margin: 130px auto 106px auto;
    }

    .containerGrid {
      margin-left: 0;
    }
  }

  .backToBrands {
    position: absolute;
    left: 70px;
    top: 50%;
    margin-top: -5px;
  }

  &.slider-history {
    .contentBox {
      overflow: hidden;
      display: flex !important;
      flex-wrap: wrap;

      .blocksSlide {
        width: calc(100% - 580px);
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;

        .boxComponent {
          box-sizing: border-box;
          width: calc(50% - 15px);
        }
      }
    }
  }

  &.lastGrid {
    width: 1042px;

    @media (max-width: 820px) {
      width: auto;
    }
  }

  .contentTerms {
    width: 1042px;
    margin: 0 auto;

    @media(max-width: 1042px){
      width: 100%;
    }
  }
}

#slider {
  overflow: hidden;
}

#readMore {
  border-top: 1px solid #ccc;

  .centerComponent {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 50px;

    @media (max-width: 820px) {
      margin-top: 20px;
      margin-bottom: 40px;
      margin-left: 23px;
      margin-right: 23px;
      text-align: -webkit-center;
      flex-direction: column;
    }
  }

  #backBrands {
    @media (max-width: 820px) {
      margin-bottom: 18px;
      a {
        color: #4a4a4a;
        font-size: 12px;
        line-height: 22px;
        span {
          border-bottom: 1px solid $GREEN_LIGHT;
        }
      }
    }
  }
}

.boxLookBook {
  width: 580px;
  max-height: 700px;
  display: flex;
  align-items: center;

  img {
    height: 100%;
  }
}

.contentTerms {
  margin-top: -20px;
}

.btn-show-filter {
  display: none;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
  border: 1px solid black;
  color: black;
  @extend %BUTTON_BLACK;

  @media (max-width: 500px) {
    display: block;
  }
}

.clicked {
  background-color: black;
  color: #fff;
}

#mainCollection {
  &.centerComponent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .leftCollection {
      width: 192px;

      a.brand-link {
        text-decoration: none;
        color: #656565;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 20px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
      }

      .clearAllFilter {
        a {
          color: #71cfc9;
          font-size: 11px;
          cursor: pointer;
        }
      }

      @media (max-width: 820px) {
        width: 100%;
        z-index: 999;
        display: flex;
        justify-content: center;
        border-bottom: 0px;
        display: none;
      }
    }

    .opened {
      height: auto;
      border-bottom: 1px solid #4a4a4a;
      display: block;
      margin-top: 20px;
    }

    .rightCollection {
      width: calc(100% - 200px);
      @media (max-width: 500px) {
        width: 100%;
      }
    }
  }
}

// Page brand, style not available

.messageCatchBrand{
  background: #f1ede9;
  position: absolute;
  z-index: 99;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d3d3d3;
  padding: 0px 20px;
  top: 120px;

  h1{
    font-size: 18px;
    text-align: center;
  }
}

#sotBy {
  width: 945px;
  margin-bottom: 26px;
}

.titleCollection {
  /*h3 {
			margin-bottom: 21px;
		}*/
}

.formItem {
  margin: 20px auto 0 auto;

  input[type="text"],
  input[type="password"],
  input[type="email"] {
    width: calc(100% - 10px);
    height: 25px;
    padding: 5px;
    margin-top: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
  }
}

.containerBackToBrands {
  position: absolute;
  left: 0;
  top: 0;
}
.theBrandHeaderWrapper {
  overflow: hidden;
  perspective: 2px;
  transform-style: preserve-3d;
  @media (min-width: 1024px) {
    height: 500px;
  }
  @media (max-width: 820px) {
    height: 320px;
    background-size: cover;
    background-position: center;
  }
  .connect-btn {
    text-transform: uppercase;
    background-color: #ffffff;
    color: #f9635d;
    position: absolute;
    border-radius: 0 0 10px 10px;
    z-index: 10;
    white-space: nowrap;
    padding: 1rem;
    width: 6.5rem;
    top: 0;
  }
  .connect-btn.pending {
    background-color: #f9635d;
    color: #ffffff;
    width: 11.5rem;
  }
  .theBrandHeader {
    position: relative;
    transform-style: inherit;
    @media (min-width: 1024px) {
      height: 500px;
    }
    @media (max-width: 1024px){
      height: 420px;
    }
    @media (max-width: 820px) {
      height: 320px;
    }
    z-index: -1;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    transform: translateZ(-1px) scale(1.5);

    @media (max-width: 500px) {
      background-attachment: inherit;
    }

    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      content: '\A';
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: rgba(0, 0, 0, 0.2);
    }

    .brand-title {
      color: white;
      z-index: 2;
      font-size: 72px;
      letter-spacing: 2px;
      // line-height: 20px;
      font-family: "PolySans-Slim";
      text-transform: uppercase;
      text-align: center;

      @media (max-width: 820px) {
        font-size: 50px;
        line-height: 50px;
      }
    }

    .logo {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 20;

      .mask {
        position: absolute;
        z-index: 1;

        @media (min-width: 1024px) {
          margin-top: 50px;
        }
      }

      .brandLogo {
        position: absolute;
        z-index: 2;
        max-width: 357px;

        @media (max-width: 500px) {
          max-width: 257px;
        }
      }
    }
  }
}
.aboutHistory {
  position: relative;

  @media (min-width: 1024px) {
    height: 620px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .aboutImage {
    width: 100%;
    height: 620px;
    object-fit: cover;

    &.mobile {
      height: 517px;
      display: none;
    }
  }

  .logo {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;

    .mask {
      position: absolute;
      z-index: 1;

      @media (min-width: 1024px) {
        margin-top: 50px;
      }
    }

    .brandLogo {
      position: absolute;
      z-index: 2;
      max-width: 357px;
      @media (min-width: 1024px) {
        margin-top: 50px;
      }
    }
  }
}

@media (max-width: 820px) {
  .aboutHistory {
    height: 320px;

    .aboutImage {
      width: 100%;
      height: 320px;
      display: none;

      &.mobile {
        display: block;
      }
    }

    .logo {
      padding: 0 45px;
      overflow: hidden;
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;

      .mask {
        position: absolute;
        z-index: 1;
      }

      .brandLogo {
        max-width: 100%;
        width: calc(100% - 90px);
      }
    }
  }

  .centerComponent {
    position: static;

    &.margin {
      margin: $MARGIN;
    }

    &.mgBottom {
      margin: 40px auto 20px auto;

      &.zero {
        margin-bottom: 0px;
      }
    }

    &.shadowMob {
      overflow: hidden;

      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 20px;
        box-sizing: border-box;
      }
    }

    .backToBrands {
      position: absolute;
      left: 70px;
      top: 50%;
      margin-top: -5px;
    }

    &.slider-history {
      padding-left: 20px;

      // .slick-track{
      // 	width: auto !important;

      // 	.slick-slide{
      // 		width: 214px !important;
      // 	}

      // }

      // .slick-arrow{
      // 	display: block !important;
      // }

      .slick-next {
        right: 0;
      }
      .slick-prev {
        left: 0;
        z-index: 9999;
      }

      .contentBox {
        overflow: hidden;
        display: flex !important;
        flex-wrap: wrap;

        .blocksSlide {
          width: calc(100% - 580px);
          box-sizing: border-box;
          display: flex;
          flex-wrap: wrap;

          .boxComponent {
            box-sizing: border-box;
            width: calc(50% - 15px);
          }
        }
      }
    }

    &.lastGrid {
      width: 1042px;

      @media (max-width: 820px) {
        width: auto;
      }
    }

    .contentTerms {
      width: auto;
      margin: 0 auto;
    }
  }

  .containerBackToBrands {
    left: 10px;
    top: 70px;
    z-index: 99;
  }
}

@media (max-width: 820px) and (orientation: landscape) {
  .aboutHistory {
    height: auto;
    .aboutImage {
      display: block;
      height: auto;
      width: 100%;

      &.mobile {
        display: none;
      }
    }

    .logo {
      padding: 0 45px;
      overflow: hidden;
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;

      .mask {
        position: absolute;
        z-index: 1;
      }

      .brandLogo {
        max-width: 50%;
        width: calc(100% - 90px);
      }
    }
  }
}
.errorConectionPopUp{
  position: fixed;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1.5rem;
  background-color: white;
  color: #333;
  border-radius: 0.25rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}