#messages-sidebar {
  max-width: 320px;
  margin: 30px;

  #buttons {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 30px;

    .button {
      margin: 0 5px 0 0;
    }

    button {
      background: transparent;
      border: 2px solid #636363;
      color: #636363;
    }

    .button--active {
      background: #636363;
      color: white;
    }
  }

  #messages-container {
    // max-width: 480px;
    display: flex;
    flex-direction: column;
    max-height: 600px;
    overflow-y: auto;
    background-color: white;
  }

  #see-more {
    background-color: white;
    width: 100%;
    padding-top: 20px;
    height: 60px;
    display: flex;
    justify-content: center;

    .button {
      background-color: #9b9b9b;
      display: flex;
      justify-content: center;
    }
  }
}
