@import "../../../shared/style/Base";
.lightBoxFullScreenWrapper {
  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #000000;
  }
  ::-webkit-scrollbar-track {
    background: #666666;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: #666666;
  }
  ::-webkit-scrollbar-track:active {
    background: #333333;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
}
.termsAndConditionsModal {
  .blockLightBox {
    padding: 0px;
    .containerText {
      padding: 15px;
      background: #fff;
    }
  }
}
.lightBoxFullScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow-y: scroll;

  .bgLightBox {
    background: #000000;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    opacity: 0.6;
    z-index: 12;
  }

  .containerLightBox {
    width: 100%;

    position: relative;

    margin: 0 auto;
    left: 0;
    top: 90px;
    z-index: 14;

    @media (max-width: 820px) {
      width: 95vw;
      margin-left: 10px;

      .blockLightBox {
        padding: 0 !important;
      }

      .sizeChart-table-wrapper,
      .imageLightBox {
        overflow-x: auto;
      }

      .titleBox {
        max-width: 70vw;
      }

      .closed {
        right: 10px !important;
      }
    }

    .btn-perfect-fit {
      margin-top: 30px!important;
    }

    .button {
      width: fit-content;
      padding: 0 20px;
      margin-top: 10px;
    }

    .imageLightBox {
      text-align: center;
    }

    .containerText {
      p {
        font-size: 14px;
        margin: 25px 0;

        strong {
          font-weight: bold;
        }

        i {
          font-style: italic;
        }

        .email {
          color: #337ab7;
          text-decoration: none;
        }
      }
    }

    .btnLightBox {
      margin-top: 25px;
      margin-bottom: 15px;
      padding: 10px 10px;
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
      color: #fff;
      border: 0;
      background: $GREEN_LIGHT;
      transition: background-color 0.05s linear;
      display: inline-block;
      cursor: pointer;
    }

    .topLightBox {
      width: 100%;
      color: #ffffff;
      max-width: 950px;
      height: 65px;
      margin: 0 auto;
      position: relative;
      display: flex;
      align-items: center;
      @extend %Montserrat;

      .titleBox {
        font-size: 24px;
        font-weight: 400;
        color: #fbfbfb;
        text-transform: uppercase;
        margin-bottom: 20px;

        span {
          font-size: 18px;
          display: block;
          margin-top: 5px;
        }
      }
    }

    .blockLightBox {
      width: 100%;
      max-width: 950px;
      height: 100%;
      margin: 0 auto;
      padding: 20px;
    }

    .__title {
      text-transform: uppercase;
      font-weight: 400;
      line-height: 24px;
      font-size: 18px;
      color: #fbfbfb;
      margin-top: 65px;
    }
    .closed {
      font-size: 22px;
      position: absolute;
      top: 0;
      right: 0;
      width: 35px;
      height: 35px;
      outline: 0;
      color: #fff;
      background: black;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
  &.videoLightBox {
    .blockLightBox {
      width: 700px;
      height: 350px;
      padding: 0px;
      @media (max-width: 820px) {
        width: 100%;
      }
    }
    .containerLightBox {
      .topLightBox {
        width: 700px;
        @media (max-width: 820px) {
          width: 100%;
        }
      }
    }
  }
}
