.connections-buttons {
  .button {
    margin: 0 10px;
    height: 30px;
    width: 100px;
  }
  .accept {
    background-color: #666666;
  }
  .decline {
    background-color: transparent;
    border: 2px solid #6e6e6e;
    color: #6e6e6e;
  }

  .accepted {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .button {
      background-color: #b8b8b8;
    }

    .envelop {
      margin-right: 15px;
      color: #b8b8b8;
      cursor: pointer;
    }
  }
}
