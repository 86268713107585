.LocationsComponent {
    width: 50%;
    margin-left: 10px;

    .title {
        font-size: 14px;
        text-transform: uppercase;
        color: #6e6e6e;
        font-weight: 600;
    }

    .locations-wrapper {
        color: black;
        font-weight: 800;
        font-size: 20px;
        text-transform: uppercase;
    }
}