#social-media {
	width: 90%;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: 30px;
	margin-top: 33px;

	li {
		width: 25px;
		height: 25px;
		margin-right: 10px;
		line-height: 25px;
		cursor: pointer;

		a {
			width: 25px;
			height: 25px;
			color: #000000;
		}

		img {
			width: 100%;
			height: 100%;
		}
	}
}
