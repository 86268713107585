@import "../../../shared/style/Base";


.lh-modal-mask {
  background-color: rgba(0, 0, 0, .5);
  top: 0;
  position: fixed;
  height: 100%;
  width: 100%;
}
.lh-modal-wrapper{

  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: opacity .3s ease;
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 24px none #ffffff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #000000;
  }
  ::-webkit-scrollbar-track {
    background: #666666;
    border: 0px none #ffffff;
    border-radius: 52px;
  }
  ::-webkit-scrollbar-track:hover {
    background: #666666;
  }
  ::-webkit-scrollbar-track:active {
    background: #333333;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }

  h1{
    text-transform: uppercase;
    text-align: center;
    font-size: 22px;
    margin-bottom: 20px;
    @extend %Montserrat;
  }

  h3{
    color: #666666;
    font-family: $FONT;
    font-weight: 600;
    margin: 20px 0;
    font-size: 14px;
  }

  h2{
    margin: 25px 0;
    font-size: 16px;
    font-weight: 800;
    color: #71cfc9;
    text-align: center;
    text-transform: uppercase;
    @extend %Montserrat;
  }

  p, li{
    color: #696969;
    font-size: 14px;
    line-height: 20px;
    font-family: "Polysans-Slim",Helvetica,Arial,sans-serif;
  }

  ol{
    list-style-type: decimal;
    li{
      margin: 15px 0;
    }
  }


  u {
    text-decoration: underline;
  }

  u { text-decoration: underline; }
  strong { font-weight: bold; }
  em { font-style: italic}
}

.lh-modal-container {
  position: relative;
  margin: 0 auto;
  padding: 50px 30px 30px 30px;
  border-radius: 4px;
  transition: all .3s ease;
  display: flex;
  background: #FFF;
  border: 0 solid #71cfc9;
  overflow-y: auto;
  max-height: 70vh;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);

  @media (max-width: 820px){
    width: 100%;
    padding: 50px 0;
    margin-top: 20%;
    height: 80%;
  }
  .close{
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    z-index: 999;
  }
  .lh-modal-body{
      display: block;
      overflow: auto;
      width: 600px;
      height: auto;
      overflow-y: auto;

      @media (max-width: 820px){
        width: 100%;
        padding: 0 20px;
      }

  }
}
.lh-modal-title{
  text-transform: uppercase;
  text-align: center;
  font-size: 22px;
  padding-bottom: 30px;
  @extend %Montserrat
}
