.brand-box.brand-box-component {
    position: relative;

    .brand--name {
        position: absolute;
        color: white;
        text-align: center;
        font-size: 21px;
        letter-spacing: 1px;
        line-height: 20px;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
        z-index: 2;
    }

    .opacity {
        position: absolute;
        opacity: 0.5;
        background: #000;
        width: 93%;
        height: 88.5%;
        z-index: 1;
        left: 3.5%;
        top: 5.7%;
        cursor: pointer;
    }

    button {
        all: unset;
        position: absolute;
        top: 50px;
        left: 12%;
        z-index: 2;
        background: white;
        width: 70%;
        padding: 0.7rem 0.5rem;
        border-radius: 5rem;
        color: black;
        cursor: pointer;
        opacity: 0.8;
        font-size: 14px;
        text-align: center;

        @media (max-width: 820px) {
            font-size: 12px;
            padding: 0.3rem 0.5rem;
        }
    }

    .containerImage {
        width: 268px;
        height: 306px;
        margin: 20px 10px;
        filter: drop-shadow(5px 8.66px 35px rgba(0, 0, 0, 0.1));
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        overflow: hidden;

        &:before {
            content: '\A';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.2);
            opacity: 1;
            transition: all 1s;
            -webkit-transition: all 1s;
            cursor: pointer;
        }

        p {
            width: 100%;
            text-align: center;
            margin: 10px 0;
            text-transform: uppercase;
            color: #656565;
            font-size: 14px;
        }

        a {
            color: white;
            text-align: center;
            font-size: 21px;
            letter-spacing: 1px;
            line-height: 20px;
        }

        .blockBuyer {
            width: 100%;
            height: 100%;
            margin: 10px 0;
            overflow: hidden;
            position: relative;
            cursor: pointer;
            background-repeat: no-repeat;
            background-size: auto;
            background-position: 50% 50%;
            box-sizing: border-box;
            border-radius: 5px;
            font-size: 28px;
            display: flex;
            justify-content: center;
            align-items: center;

            &.blockBuyer--buyer {
                a {
                    text-transform: uppercase;
                }
            }

            div {
                width: 100%;
                height: 100%;
                background-color: white;
                opacity: 0.8;
                transition: opacity 0.1s linear;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #666;
                font-weight: bold;
            }

            &.blockBuyer--bg {
                a {
                    display: none;
                }
            }

            img {
                width: 100%;
                opacity: 0.1;
                transition: opacity 0.1s linear;
            }
            &:hover {
                img {
                    opacity: 1;
                }
                div {
                    opacity: 0;
                }
            }
            .hover {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                opacity: 0.8;
                background: #cccccc;
                display: flex;
                align-items: center;
                justify-content: center;

                a {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    opacity: 0.8;
                    background: #cccccc;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            &:hover {
                .hover {
                    display: none;
                }
            }
        }
    }

    @media (max-width: 820px) {
        .containerImage {
            width: calc(100vw / 2 - 15px);
            height: 185px !important;
            margin: 5px;
            display: flex;
            height: auto;
            overflow: hidden;
            position: relative;

            .blockBuyer {
                height: auto;
                width: 100%;
                overflow: hidden;
                position: relative;
                cursor: pointer;

                a {
                    height: auto;
                }

                img {
                    max-width: 100%;
                }
            }
        }
    }
}
