.box-input-profile{
    display: flex;
    flex-direction: column;
    text-align: start;
    width: 432px;
    position: relative;

    & > label{
        width: fit-content;
        color: #000;
        font-family: "PolySans-Slim", sans-serif;
        margin-bottom: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
        cursor: pointer;
    }

    .input-required{
        position: relative;

        &::after{
            content: '*';
            color: red;
            position: absolute;
            right: -10px;
            top: 0;
        }
    }

    & > .box-input-profile__input{
        padding: 6px 10px;
        border-radius: 5px;
        height: 43px;
        border: 1px solid #D4D4D4;
        transition: all 300ms ease-in;

        &:focus{
            padding-left: 15px;
        }

        @media (max-width: 960px){
            height: 36px;
        }
    }

    .box-input-profile__validation{
        font-size: 14px;
        margin-top: 5px;
        color: red;
        position: absolute;
        bottom: -18px;
        left: 0;
    }
}
