@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: none; }

button {
  border: none;
  outline: none; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  font-family: "PolySans-Slim";
  background: #ffffff; }

@media (max-width: 820px) {
  html, body {
    max-width: 820px;
    width: 100%;
    overflow-x: hidden; } }

/* @media (max-width: 1366px) {
    %CENTER_COMPONENT {
        width: 90%;
        margin: 0 auto;
    }

    %CENTER_COMPONENT_SMALL {
        width: 80%;
        margin: 0 auto;
    }
} */
.variation-tooltip {
  color: #6d6d6d;
  font-weight: bold;
  box-shadow: 0px 2px 6px #99999940;
  border-radius: 5px;
  padding: 6px 10px; }
  @media (max-width: 820px) {
    .variation-tooltip {
      max-width: 50px; } }

#MenuFooterComponent {
  width: 301px;
  display: flex;
  margin-top: 50px;
  justify-content: space-between; }
  @media (max-width: 820px) {
    #MenuFooterComponent {
      width: 100%; } }
  #MenuFooterComponent p.title {
    font-size: 24px;
    color: #ffffff; }
  #MenuFooterComponent ul {
    width: 100%; }
    #MenuFooterComponent ul li {
      width: 100%;
      font-size: 14px;
      color: #ffffff;
      line-height: 30px;
      font-weight: 400;
      position: relative; }
      #MenuFooterComponent ul li a {
        color: #FFFFFF;
        font-family: "PolySans-Slim";
        font-size: 14px;
        line-height: 28px; }
      #MenuFooterComponent ul li:first-child {
        box-sizing: border-box;
        padding-bottom: 18px; }

@media (max-width: 820px) {
  #MenuFooterComponent {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px; }
    #MenuFooterComponent ul {
      width: 100%; }
      #MenuFooterComponent ul li {
        width: 100%;
        line-height: 28px;
        font-weight: 300;
        position: relative; }
        #MenuFooterComponent ul li a {
          color: #FFFFFF;
          font-size: 12px;
          line-height: 26px;
          font-weight: 300; }
        #MenuFooterComponent ul li:first-child {
          font-size: 16px;
          font-weight: 600;
          line-height: 63px;
          height: 63px;
          padding-bottom: 0; }
          #MenuFooterComponent ul li:first-child:before {
            width: 100%;
            height: 3px; }
          #MenuFooterComponent ul li:first-child a {
            font-size: 16px;
            font-weight: 600; } }
