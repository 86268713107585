@import "../../../shared/style/Base";

.brandLightBox{
  .lh-modal-mask{
    @media (max-width: 990px){
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
  .lh-modal-wrapper {
    display: flex;
    max-height: 100%;
    @media (max-width: 990px){
      overflow: auto;
      padding-top: 50px;
      max-height: unset;
      height: calc( 100% - 100px);
    }
  }

  .lh-modal-container {
    padding: 0px;
    height: 649px;
    max-height: 100%;

    @media (max-width: 990px){
      width: calc(100% - 60px);
      height: auto;
      max-height: 100%;
    }
    .lh-modal-body{
        display: flex;
        overflow: auto;
        width: 941px;

        @media (max-width: 990px){
          flex-direction: column;
          width: calc(100% - 40px);
          height: auto;
          overflow-y: scroll;
          padding: 25px 20px;
        }

        .close{
          position: absolute;
          right: 20px;
          top: 20px;
          cursor: pointer;

          @media (max-width: 990px){
            right: 0px;
            top: -20px;
          }
        }
    }
    .image-wrapper{
      width: 500px;
      height: 649px;
      flex-basis: 500px;
      flex-shrink: 0;
      overflow: hidden;

      @media (max-width: 990px){
        height: 272px;
        flex-basis: auto;
        width: 100%;
      }

      img{
        height: 649px;
        object-fit: cover;

        @media (max-width: 990px){
          width: 100%;
          height: 272px;
        }
	  }

		.slick-prev {
			position: absolute;
			background-image: url('/images/arrowLeft.svg');
			left: 45px;
			z-index: 2;
      filter: invert(1);
	  	}
	  	.slick-next {
			position: absolute;
			background-image: url('/images/arrowLeft.svg');
			right: 30px;
      transform: rotate(180deg);
      filter: invert(1);
      top: calc(52% - 60px);
		}

      .slick-dots {
    		bottom: 20px;

    		li {
    			width: 11px;
    			height: 11px;
    			border: 2px solid #ffffff;
    			border-radius: 100%;
    			box-sizing: border-box;

    			button {
    				&:before {
    					display: none;
    				}
    			}

    			&.slick-active {
    				background: #ffffff;
    			}
    		}
    	}
    }
    .info-wrapper{
      display: flex;
      width: 441px;
      flex-direction: column;
      position: relative;
      padding: 120px 40px 0px 80px;
      @media (max-width: 990px){
        width: 100%;
        padding: 0;
      }
      .tags{
          text-align: left;
          color: #000000;
          font-family: $FONT;
          font-size: 13px;
          font-weight: bold;
          line-height: 18px;
          span{
            margin-right: 5px;
          }
          @media (max-width: 990px){
            margin-top: 60px;
            margin-bottom: 20px;
            text-align: center;
          }
      }
      .brand-name{
        color: #000000;
        font-family: $FONT;
        font-size: 17px;
        font-weight: bold;
        letter-spacing: 4.2px;
        line-height: 23px;
        margin: 35px 0 15px 0;
        text-align: left;

        @media (max-width: 990px){
          position: absolute;
          margin: 0px 10px;
          width: calc(100% - 40px);
          padding: 15px 10px;
          top: -15px;
          background: #FFF;
          text-align: center;
        }
      }
      .brand-text{
        text-align: left;
        color: #787878;
        font-family: $FONT;
        font-size: 14px;
        line-height: 21px;
        .description{
          display: none;
          @media (max-width: 990px){
            display: block;
            &.hide{
              display: none;
            }
          }
        }
        .text{
          @media (max-width: 990px){
            transform: scaleY(0);
            transition: transform .5s;
            transform-origin: top left;
            overflow: hidden;
            max-height: 0;
          }
          &.show{
            max-height: 100%;
            transform: scaleY(1);
          }
        }

      }
      .read-more{
        display: none;
        color: #000000;
        font-family: $FONT;
        font-size: 14px;
        font-weight: 300;
        line-height: 19px;
        position: relative;
        padding: 20px 0 3px 0;
        cursor: pointer;
        @media (max-width: 990px){
          display: block;
        }
        &::after{
          content: '';
          width: 30px;
          height: 1px;
          bottom: 0;
          background: #000000;
          position: absolute;
          left: calc(50% - 15px);
        }
        &.hide{
          display: none;
        }
      }
      .footer{
        text-align: right;
        margin-top: auto;
        margin-bottom: 50px;
        font-family: $FONT;
        font-size: 11px;
        font-weight: 800;
        @media (max-width: 990px){
          display: none;
          margin-bottom: 0px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          &.show{
            display: flex;
          }
        }

        .register{
          @extend %BUTTON_BLACK
        }
        .open-login{
          background: transparent;
          color: #000000;
          font-size: 11px;
          font-weight: 800;
          border: none;
          box-shadow: none !important;;
          width: 50%;
          @media (max-width: 990px){
            margin-top: 20px;
          }
        }
      }
      .discover{
        margin: 25px 0;
        color: #666666;
        font-family: $FONT;
        font-size: 11px;
        font-weight: 800;
        line-height: 15px;
        text-align: left;
      }

    }
  }

}
