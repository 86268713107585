@import "../../../shared/style/Base";

#create-my-own-list-component {
  .jumboTitle {
    height: 250px;
	margin-bottom: 0;
  }
  .textLineSheet {
    width: 700px;
    border: 1px solid #666;
    padding: 10px;
    font-size: 14px;
    line-height: 18px;
    vertical-align: bottom;
    margin-top: 50px;
    color: #666;
    font-family: "PolySans-Slim", sans-serif;

    @media (max-width: 820px) {
      width: 90%;
    }

    p {
      .cartText {
        display: inline-block;
        width: 14px;
        height: 14px;
        margin: 0 5px;
        background: #000000;
        -webkit-mask: url("/images/create-my-own-list/cart.svg") no-repeat
          center center;
        mask: url("/images/create-my-own-list/cart.svg") no-repeat center center;
        mask-size: cover;
      }

      .downloadText {
        display: inline-block;
        width: 15px;
        height: 15px;
        margin: 0 5px;
        background: #000000;
        -webkit-mask: url("/images/create-my-own-list/download.svg") no-repeat
          center center;
        mask: url("/images/create-my-own-list/download.svg") no-repeat center
          center;
      }
    }
  }

  .select-all-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .select-all-button {
      margin-top: 35px;
      width: fit-content;
      padding: 10px;

      &.select-all-button--selected {
        background-color: transparent;
        border: 2px solid #b3b3b3;
        color: #b3b3b3;
        font-weight: 600;
        letter-spacing: 2px;
      }
    }
  }
}

#lineSheetColletion {
  width: 870px;
  position: relative;

  @media (max-width: 820px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h1 {
    font-style: italic;
    font-size: 26px;
    color: #666;
    text-align: center;
    @extend %Montserrat;
    margin: 50px 0 25px 0;
  }

  h2 {
    font-size: 42px;
    color: #666;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    margin-bottom: 35px;
  }
  .brand-name {
    @extend %Montserrat;

    @media (max-width: 820px) {
      font-size: 40px;
    }
  }

  .containerProduct {
    width: 870px;
    margin: 0 auto;

    @media (max-width: 820px) {
      width: 100%;
    }
  }

  .cartAndDownload {
    position: fixed;
    bottom: 50px;
    z-index: 200;
    width: 870px;
    text-align: right;

    @media (max-width: 820px) {
      width: 100%;
    }

    ul {
      display: flex;
      justify-content: flex-end;

      @media (max-width: 820px) {
        justify-content: flex-start;
      }

      li {
        width: 60px;
        height: 60px;
        margin-right: 20px;
        background: #000000;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
        opacity: 0.7;
        position: relative;
        cursor: pointer;

        p {
          position: absolute;
          bottom: -30px;
          text-align: center;
          font-size: 12px;
        }

        span {
          width: 100%;
          height: 100%;
          -webkit-mask-size: 100%;
          mask-size: 100%;
          background-color: #fff;
          transition: all linear 0.2s;
          display: flex;
          justify-content: center;
          align-content: center;
        }

        &.cart {
          span {
            -webkit-mask: url("/images/create-my-own-list/cart.svg") no-repeat
              center center;
            mask: url("/images/create-my-own-list/cart.svg") no-repeat center
              center;
          }
        }

        &.download {
          span {
            width: 25px;
            height: 25px;
            margin-top: 15px;
            -webkit-mask: url("/images/create-my-own-list/download.svg")
              no-repeat;
            mask: url("/images/create-my-own-list/download.svg") no-repeat;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
    /*margin-left: -435px; */
  }
}
