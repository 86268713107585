.container-form-personal{
    display: flex;
    flex-direction: column;
    margin-top: 140px;
    max-width: 1040px;
    padding-bottom: 193px;
    animation: on 0.5s ease-in-out;

    @keyframes on {
        from {
          opacity: 0;
        }
      
        to {
         opacity: 1;
        }
    }

    @media (max-width: 1140px){
        margin-top: 80px; 
        padding-bottom: 100px;             
    }

    @media (max-width: 960px){
        width: 80%;
        margin-top: 50px;  
        padding-bottom: 30px;      
    }
}

.container-form-personal__box-inputs{
    display: flex;
    align-items:flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 27px 90px;
}

.container-form__personal-btns{
    margin-top: 27px;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 960px){
        justify-content: center;

        .buttonWithBgProfile{
            margin-left: 0;
        }
    }

    .buttonWithBgProfile{
        @media (max-width: 620px){    
            margin-bottom: 1rem;
        }
    }
}

.container-form-personal__message-request{
    width: fit-content;
    margin: 1rem 0 0 2.7rem;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img{
        margin-left: 4px;
        height: 19px;
    }
}

.select-phone{
    width: 100%;
    height: 43px; 
}

.react-tel-input .form-control{
    height: 43px;
    width: 100%;
}

.component-phone{
    display: flex;
    flex-direction: column;
    width: 432px;
}

.input-required-phone{
    position: relative;
    text-align: left;
    margin-bottom: 10px;

    &::after{
        content: '*';
        color: red;
        position: absolute;
        left: 46px;
        top: 0;
    }
}

.box-input-phone-validation{
    color: red;
    text-align: left;
    margin-top: 5px;
}