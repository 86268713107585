.container-form-management{
    display: flex;
    flex-direction: column;
    margin-top: 140px;
    max-width: 954px;
    padding-bottom: 138px;
    animation: on 0.5s ease-in-out;

    .container-form-management__title{
        all: unset;
        color: #000;
        font-family: 'Polysans-Slim', sans-serif;
        font-size: 26px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
        margin-bottom: 27px;
        text-align: start;
        
        & + .container-form-management__subtitle{
            margin-top: -20px;

            @media (max-width: 960px){
                margin-top: 0;
            }
        }

        @media (max-width: 960px){
            margin-bottom: 10px;
        }
    }

    .container-form-management__subtitle{
        color: #626262;
        font-family: 'Polysans-Slim', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 21px;
        text-align: start;
    }

    .container-form-management__subsubtitle{
        align-self: flex-start;
        margin: 0.2rem 0;
    }

    .box-inputs__box-img-name{
        display: flex;
        width: fit-content;
    }

    .container-form-management__domains {
        display: flex;
        align-items: center;
        gap: 0.1rem;
        padding: 0.8rem 0.3rem;
        border: 1px solid #D4D4D4;
        border-radius: 0.2rem;

        &:hover, &:focus{
            border-color: #000;
        }

        .loading-element{
            max-width: 14px;
            margin: 0;
            padding: 0;
        }

        div{
            display: flex;
            gap: 0.1rem;
            align-items: center;

            p{
                display: flex;
                gap: 0.4rem;
                align-items: center;

                background-color: #D4D4D4;
                border-radius: 0.2rem;
                font-size: 1rem;
                padding: 0.2rem 0.3rem;

                button {
                    background-color: transparent;
                    width: fit-content;
                    height: 100%;
                }
            }
        }

        input {
            border: none;
            padding: 0 1rem;
            width: 100%;
        }
    }

    button{
        margin-left: 0px;
    }

    @keyframes on {
        from {
          opacity: 0;
        }
      
        to {
         opacity: 1;
        }
    }

    @media (max-width: 1140px){
        width: 90%;
        margin-top: 60px;
    }
}

.box-inputs__box-img-name__send-image{
    display: flex;
    width: 232px;
    height: 87px;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 6px 0px;
    flex-wrap: wrap;
    border-radius: 30px;
    background: #E6E6E6;
    cursor: pointer;
    margin-right: 40px;

    span{
        color: #000;
        text-align: center;
        font-family: 'Polysans-Slim', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;

        &:nth-child(2){
            color: #767676;
            font-size: 14px;
            line-height: 23px;
        }
    }
}

.container-form-management__box-inputs{
    display: flex;
    align-items:flex-start;
    flex-wrap: wrap;
    gap: 27px 90px;

    @media (max-width: 1140px){
        justify-content: center;
    }

}

.container-form__personal-btns{
    margin-top: 27px;

    @media (max-width: 1140px){
        display: flex;
        justify-content: center;
    }

    & + .container-form-management__title{
        margin-top: 59px;

        @media (max-width: 960px){
            margin-top: 20px;
        }
    }
}

.container-form-management__box-inputs__radios{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: start;
    width: 432px;
    height: 60px;

    & > span{
        color: #000;
        font-family: 'Polysans-Slim', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
    }
}

.container-form-management__box-inputs__radios{
    label{
        margin-left: 8px;
        margin-right: 60px;
    }
}

.container-form-management__validation{
    font-size: 14px;
    color: red;
}

.container-form-management__message-request{
    width: fit-content;
    margin: 1rem 0 0 0;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img{
        margin-left: 4px;
        height: 19px;
    }
}