.spinner {
    border: 3px solid white;
    border-left-color: #000;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to { transform: rotate(360deg); }
}