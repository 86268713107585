
@media (min-width: 820px) {
  .hero.slick-slider.slide.slick-initialized {
    height: 780px;
  }
}

@media (max-width: 1024px) {
  .hero.slick-slider.slide.slick-initialized {
    height: 600px;
  }
}

.slick-dots {
  bottom: 5px !important;

  li button:before {
    font-size: 13px !important;
    color: #d8d8d8;
    opacity: 1;
  }
}

.slick-dots li.slick-active button:before {
  color: #66dad1;
}

.slick-dots li button:hover:before {
  color: #66dad1;
}

.slick-dots li button:visited:before {
  color: #66dad1;
}
