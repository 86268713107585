.btnDropDown.active {
    background-color: #61656f;
    .label {
        color: white;

        .xMark {
            display: flex;
        }

        .settings {
            display: none;
        }
    }
}

.btnDropDown.open {
    z-index: 1;
    background-color: white;

    .list-items {
        display: block;
    }

    .label {
        color: #61656f;
        .xMark{
            fill: #61656f;
        }
        .settings {
            transform: rotate(-90deg);
        }
    }
}

.relative {
    position: relative;
}

.btnDropDown {
    all: unset;
    cursor: pointer;
    border: 1px solid #7c7f8880;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    align-items: center;
    background: white;
    width: 220px;
}

.label {
    font-family: 'PolySans-Slim';
    display: flex;
    height: fit-content;
    justify-content: space-between;
    align-items: center;
    color: #61656f;
    padding: 0.5rem 1rem;
    width: 80%;

    span {
        .xMark {
            display: none;
            fill: white;
            width: 15px;
            height: 15px;
        }
        .settings {
            display: flex;
            width: 15px;
            height: 15px;
            fill: #61656f;
            transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
    }
}

.list-items {
    position: relative;
    margin-top: 15px;
    border-radius: 16px;
    padding: 8px;
    font-family: 'PolySans-Slim';
    width: 90%;
    display: none;

    .item {
        display: flex;
        list-style: none;
        align-items: center;
        cursor: pointer;
        transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        padding: 0 15px;
        border-radius: 8px;
        padding: 0.4rem 1rem;

        &:hover {
            background: #e3e3e3;
        }

        .checkbox {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;
            border-radius: 4px;
            margin-right: 12px;
            border: 1.5px solid #61656F;
            transition: all 0.3s ease-in-out;

            .check-icon {
                display: flex;
                padding: 2px;
                fill: white;
                width: 100%;
                border-radius: 2px;
                background: #61656F;
                transform: scale(0);
            }
        }

        .item-text {
            color: #61656F;
            text-transform: lowercase;
            width: 80%;
        }
    }

    .traco {
        display: flex;
        content: '';
        width: 85%;
        height: 1px;
        background: #61656f70;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1rem;
    }

    .titleSelection {
        width: 85%;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        margin-left: auto;
        margin-right: auto;
        cursor: default;
        margin-bottom: 0.5rem;

        .selectionTitle {
            color: #7c7f88;
            font-size: 11px;
            vertical-align: baseline;
            margin-top: auto;
        }

        .clearBtn {
            color: #43a2f1;
            cursor: pointer;
            text-decoration: underline;
        }
    }

    button {
        all: unset;
        background: #000;
        color: white;
        border-radius: 2rem;
        padding: 0.5rem 1rem;
        text-transform: uppercase;
        width: 80%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1rem auto;

        &:hover {
            background: rgba(0, 0, 0, 0.7);
        }
    }

    .item.checked {
        .check-icon {
            transform: scale(1);
        }

        .item-text {
            color: #000;
        }
    }
}
