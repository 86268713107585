.loader {
  position: relative;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-radius: 50%;
  border: 3px solid white;

  border-top: 3px solid transparent;

  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
