#register-view {
    margin-bottom: 250px;
    .title-and-description {
        text-align: center;
        padding-top: 40px;
        padding-bottom: 5px;

        h2 {
            color: #666666;
            font-size: 24px;
            line-height: 24px;
            margin-bottom: 25px;
        }

        .description {
            padding-right: 20%;
            padding-left: 20%;
            line-height: 24px;
            font-size: 14px;
            color: #6bac9e;
        }
    }

    .registration-title-box {
        background: url("https://www.blancfashion.com/land/images/Register-Retailers.jpg");
        width: 100%;
        display: flex;
        height: 130px;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        p {
            font-size: 14px;
            color: #666666;
            font-weight: bold;
        }

        span {
            bottom: -50px;
        }
    }

    span.subtitle {
        color: #cbcbcb;
        font-size: 14px;
        position: relative;
        font-weight: bold;
    }

    .container {
        padding-left: 20%;
        padding-right: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .success-text {
            display: flex;
            align-items: center;
            margin: 40px 0px;

            svg {
                color: #87cec9;
            }
            span {
                margin: 20px 0px;
                color: #919191;
                font-size: 14px;
            }
        }

        .company-info, .contact-info {
            width: 535px;
        }

        .contact-info {
            .email-box {
                display: grid;
                grid-template-columns: 97% 79%;

                span {
                    font-size: 8px;
                    padding: 6px;
                    margin-top: 4px;
                    line-height: 13px;
                    color: #d95c5c;
                }
            }
        }

        > div {
            text-align: center;
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
        }

        select {
            width: 520px !important;
        }

        input, select {
            padding-left: 15px;
            font-size: 12px;
            border-radius: 5px;
            padding-top: 7px;
            padding-bottom: 7px;
            -webkit-appearance: none;
            appearance: none;
            margin-top: 6px!important;
            width: 500px;
            border: 2px solid #c0c0c0;
        }

        .add-location {
            cursor: pointer;
            display: flex;
            text-align: left;
            align-items: center;
            margin-top: 10px;

            svg {
                margin-right: 3px;
                color: #87cec9;
            }

            span {
                color: #888;
                font-size: 11px;
            }
        }

        .auto-complete-group {
            display: flex;
            align-items: center;

            svg {
                margin-left: 3px;
                cursor: pointer;
                color: #87cec9;
            }
        }
    }

    button {
        background: #fff!important;
        border: none!important;
        padding: 9px 34px 9px 34px;
        background: #87cec9!important;
        -webkit-border-radius: 40px;
        -moz-border-radius: 40px;
        border-radius: 40px;
        color: #fff;
        cursor: pointer;
        font-size: 14px;
    }

    .checkbox-group {
        display: flex;
        flex-direction: row !important;
        align-items: flex-end;
        input {
            margin-right: 10px;
            width: auto;
        }
        label {
            font-size: 12px;
            color: #c0c0c0;
        }
    }
}
