.container-card-users-management {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 800px) {
    border-bottom: 1px solid #b2b3b3;
    padding-top: 1rem;
  }

  .itens {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 30% 50% 20%;
    text-align: left;

    @media (max-width: 800px) {
      grid-template-columns: 100%;
      gap: 0.2rem;
      padding-bottom: 0.2rem;
    }
  }

  p {
    width: 100%;
    color: #000;
    font-family: "Polysans-Slim", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;

    @media (max-width: 700px) {
      padding-bottom: 0.5rem;
    }

    @media (max-width: 540px) {
      font-size: 15px;
    }
  }

  button {
    background-color: #000;
    width: 2rem;
    height: 2rem;

    &:disabled{
      background-color: #808080;
    }
  }

  img {
    fill: white;
    background-color: transparent;
    width: 1rem;
    height: 1rem;
  }

  & + .container-card-users-management {
    margin-top: 6px;
  }

  & + .container-form-management__title {
    margin-top: 77px !important;

    @media (max-width: 1140px) {
      margin-top: 45px !important;
    }
  }
}
