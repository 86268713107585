.select{
    text-align: start;
    width: 432px;

    & > label{
        display: inline-block;
        color: #000;
        font-family: "PolySans-Slim", sans-serif;
        margin-bottom: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
        cursor: pointer;
    }

    .select-item{
        width: 100%;
        height: 43px;
    }

    .item-select{
        background-color: red !important;
    }
}