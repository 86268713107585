@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: none; }

button {
  border: none;
  outline: none; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  font-family: "PolySans-Slim";
  background: #ffffff; }

@media (max-width: 820px) {
  html, body {
    max-width: 820px;
    width: 100%;
    overflow-x: hidden; } }

/* @media (max-width: 1366px) {
    %CENTER_COMPONENT {
        width: 90%;
        margin: 0 auto;
    }

    %CENTER_COMPONENT_SMALL {
        width: 80%;
        margin: 0 auto;
    }
} */
.variation-tooltip {
  color: #6d6d6d;
  font-weight: bold;
  box-shadow: 0px 2px 6px #99999940;
  border-radius: 5px;
  padding: 6px 10px; }
  @media (max-width: 820px) {
    .variation-tooltip {
      max-width: 50px; } }

.new-menu {
  display: flex;
  position: absolute;
  right: 70px;
  top: 25px;
  width: 20px;
  margin-top: -13px;
  cursor: default; }
  .new-menu .new-menu__icon {
    display: flex;
    cursor: pointer;
    border: none;
    background: none; }
    .new-menu .new-menu__icon__hamburguer {
      border-top: 2px solid black;
      width: 20px;
      display: block; }
      .new-menu .new-menu__icon__hamburguer::after, .new-menu .new-menu__icon__hamburguer::before {
        width: 20px;
        height: 2px;
        background: black;
        content: "";
        display: block;
        margin-top: 5px;
        transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        position: relative; }
  .new-menu .new-menu__list {
    all: unset;
    position: absolute;
    background: white;
    min-width: 350px;
    padding: 2rem;
    border-radius: 15px;
    width: fit-content;
    top: 60px;
    right: -15px;
    z-index: 0;
    display: none;
    flex-direction: column;
    opacity: 0;
    transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    box-shadow: 6px 4px 5px 0px rgba(0, 0, 0, 0.2); }
    @media screen and (max-height: 815px) {
      .new-menu .new-menu__list {
        height: 415px;
        overflow-y: scroll; } }
    .new-menu .new-menu__list::before {
      position: absolute;
      content: "";
      width: 0px;
      height: 0px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid white;
      top: -10px;
      right: 15px; }
    .new-menu .new-menu__list .new-menu__list--item {
      display: flex;
      flex-direction: column;
      border-bottom: 2px solid #e2e2e250;
      padding: 0.5rem 0rem; }
      .new-menu .new-menu__list .new-menu__list--item span {
        display: flex;
        width: fit-content;
        height: 22px;
        font-family: 22px !important;
        padding: 0.2rem;
        color: black; }
      .new-menu .new-menu__list .new-menu__list--item .new-menu__list--item__subitem {
        display: flex;
        flex-direction: column;
        align-items: flex-start; }
        .new-menu .new-menu__list .new-menu__list--item .new-menu__list--item__subitem a {
          font-size: 18px !important;
          color: #50545F8F !important;
          text-transform: capitalize !important;
          margin: 0.3rem 1rem; }
          .new-menu .new-menu__list .new-menu__list--item .new-menu__list--item__subitem a:hover {
            color: rgba(10, 11, 12, 0.560784) !important; }
    .new-menu .new-menu__list .icon-button {
      cursor: pointer;
      display: flex;
      align-items: center;
      width: fit-content;
      margin-left: 25px !important;
      margin-top: 1rem; }
      .new-menu .new-menu__list .icon-button span {
        display: flex;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        gap: 0.5rem; }
      .new-menu .new-menu__list .icon-button a {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        font-size: 12px !important;
        color: black; }
        .new-menu .new-menu__list .icon-button a:hover {
          color: #71cfc9 !important; }
          .new-menu .new-menu__list .icon-button a:hover svg {
            fill: #71cfc9 !important; }
      .new-menu .new-menu__list .icon-button:hover {
        color: #71cfc9; }
    .new-menu .new-menu__list .bottom-items {
      display: flex;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
      gap: 0.5rem;
      margin-left: -20px;
      color: black; }

.new-menu.active .new-menu__icon__hamburguer {
  border-top-color: transparent; }
  .new-menu.active .new-menu__icon__hamburguer::before {
    transform: rotate(135deg); }
  .new-menu.active .new-menu__icon__hamburguer::after {
    transform: rotate(-135deg);
    top: -7px; }

.new-menu.active .new-menu__list {
  opacity: 1;
  display: flex; }
