.recovery{
  padding: 130px 0 100px 0;
  background: url('/images/forgot_page.png') no-repeat center center fixed;
  background-size: cover;
  .recovery-form-wrapper{
    width: calc(100vw - 80px);
    max-width: 350px;
    margin: 0 auto;
    background: #FFF;
    padding: 20px 30px;
    box-shadow: 2px 3px 11px 0px rgba(0, 0, 0, 0.35);
    height: 230px;
    h2{
      color: #000;
      font-weight: 600;
      font-size: 24px;
      text-transform: uppercase;
      text-align: center;
      margin: 20px 0;
    }
    .error{
      color: red;
      font-size: 11px;
    }
    .btn-wrapper{
      display: flex;
      justify-content: center;
      text-align: center;

      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
      }
    }
  }
}
