@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: none; }

button {
  border: none;
  outline: none; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  font-family: "PolySans-Slim";
  background: #ffffff; }

@media (max-width: 820px) {
  html, body {
    max-width: 820px;
    width: 100%;
    overflow-x: hidden; } }

/* @media (max-width: 1366px) {
    %CENTER_COMPONENT {
        width: 90%;
        margin: 0 auto;
    }

    %CENTER_COMPONENT_SMALL {
        width: 80%;
        margin: 0 auto;
    }
} */
.variation-tooltip {
  color: #6d6d6d;
  font-weight: bold;
  box-shadow: 0px 2px 6px #99999940;
  border-radius: 5px;
  padding: 6px 10px; }
  @media (max-width: 820px) {
    .variation-tooltip {
      max-width: 50px; } }

.filters .titleFilters {
  font-size: 9px;
  margin: 28px 0 25px 19px;
  font-weight: 600;
  font-style: italic;
  font-family: "PolySans-Slim";
  text-transform: uppercase;
  line-height: 13px;
  letter-spacing: 0.8px; }

.filters .listFilters {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }
  .filters .listFilters aside {
    width: 100%;
    display: flex;
    flex-wrap: wrap; }
  .filters .listFilters div {
    color: black;
    font-family: "PolySans-Slim";
    background-color: transparent;
    border: 1px solid black;
    color: black;
    text-transform: uppercase;
    padding: 10px 20px;
    border-radius: 30px;
    margin: 0 10px 10px;
    font-size: 11px;
    font-weight: 600;
    box-sizing: border-box;
    cursor: pointer; }
    .filters .listFilters div.active {
      background: #5E5F5E;
      border: 1px solid #5E5F5E;
      color: #ffffff; }
    .filters .listFilters div a {
      color: #71CFC9; }

@media (max-width: 820px) {
  .filters .titleFilters {
    font-size: 12px;
    margin: 30px 0 20px 0;
    line-height: 22px;
    letter-spacing: normal;
    font-style: normal; }
  .filters .listFilters {
    width: 100%;
    display: block;
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .filters .listFilters aside {
      width: auto; }
      .filters .listFilters aside.scroll {
        width: 600px; }
    .filters .listFilters div {
      border-width: 1px;
      display: inline-block;
      padding: 0 20px;
      height: 34px;
      line-height: 34px;
      margin: 0 10px 14px;
      font-size: 11px;
      box-sizing: border-box; }
      .filters .listFilters div:hover {
        box-shadow: none; } }
