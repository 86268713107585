@import "../../../shared/style/Base.scss";
@import "../../../shared/style/Mixins.scss";

.button {
    display: inline-block;
    cursor: pointer;
    @extend %BUTTON_BLACK;

    &:disabled {
        opacity: 0.5;
    }
}

button {
    &.primary {
        background-color: #f9635d !important;
        color: white !important;
        border: none !important;
        padding: 10px;
        width: auto;
        height: 32px;
    }

    &.secondary {
        background-color: white;
        color: #61656f;
        padding: 10px;
        width: auto;
        height: 32px;
        border: #282626 solid 1px;
    }

    a {
        text-decoration: none;
        color: inherit;
    }
}

@media (max-width: 820px) {
    .button {
        height: 34px;
        font-size: 14px;
        font-weight: normal;
        padding: 0 20px;
    }
}
