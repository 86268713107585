.container-form-adress {
  display: flex;
  flex-direction: column;
  margin-top: 140px;
  max-width: 954px;
  padding-bottom: 138px;
  animation: on 0.5s ease-in-out;

  @media (max-width: 1280px) {
    width: 90%;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1140px) {
    margin-top: 80px;
    padding-bottom: 40px;
  }

  @media (max-width: 768px) {
    margin-top: 60px;
    width: 97%;
  }

  .container-form-adress__title {
    all: unset;
    text-align: start;
    color: #000;
    font-family: "Polysans-Slim", sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    margin-bottom: 27px;

    @media (max-width: 1280px) {
      text-align: center;
    }
  }

  .box-inputs__box-img-name {
    display: flex;
    width: fit-content;
  }

  button {
    margin-left: 0px;
  }

  @keyframes on {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

.react-tel-input .country-list .country {
  display: flex;
  padding: 7px 1px;
}

.container-form-adress__box-cards {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 26px 30px;

  @media (max-width: 540px) {
    width: 98%;
  }

  & + .container-form-adress__title {
    margin-top: 59px;
  }
}

.box-inputs__box-img-name__send-image {
  display: flex;
  width: 232px;
  height: 87px;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 6px 0px;
  flex-wrap: wrap;
  border-radius: 30px;
  background: #e6e6e6;
  cursor: pointer;
  margin-right: 40px;

  span {
    color: #000;
    text-align: center;
    font-family: "Polysans-Slim", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;

    &:nth-child(2) {
      color: #767676;
      font-size: 14px;
      line-height: 23px;
    }
  }
}

.container-form-adress__box-inputs {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 27px 90px;

  @media (max-width: 1280px) {
    width: 90%;
    align-items: center;
    justify-content: center;
  }
}

.container-form__personal-btns {
  margin-top: 27px;
}

.container-form-adress__box-inputs__radios {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: start;
  width: 432px;
  height: 60px;

  & > span {
    color: #000;
    font-family: "Polysans-Slim", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
  }
}

.container-form-adress__box-inputs__radios {
  label {
    margin-left: 8px;
    margin-right: 60px;
  }
}

.box-input-type-validation {
  color: red;
  text-align: left;
  margin-top: 5px;
  font-size: 14px;
}

.adress-input {
  width: 100%;

  @media (max-width: 1280px) {
    width: 432px;
  }
}
