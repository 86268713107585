@import "../../../../shared/style/Base";

.grid1 {
	width: 100%;
	margin: 0 auto;
	// overflow: hidden;
	display: inline-block;
	padding-bottom: 10px;

	.button {
		width: fit-content;
		padding: 0 15px;
	}

	.contentLinkButton {
		width: calc(100% - 90px);
		margin-top: 10px;

		.linkShowCase {
			display: inline-block;
			margin: 0 0 15px 0;
			cursor: pointer;
			@extend %LINK_UNDERLINE;
		}

		.buttonShowCase {
			display: block;
			@extend %BUTTON_BLUE;
		}
	}

	.leftGrid {
		max-width: 605px;
		max-height: 420px;
		width: 100%;
		text-align: right;
		float: left;

		@media(max-width: 820px){
			max-width: 400px;
		}

		img {
			width: 100%;
		}
	}

	.rightGrid {
		width: calc(100% - 605px);
		box-sizing: border-box;
		float: right;

		.infoComponent {
			width: 100%;
			min-height: 60px;

			@media(max-width: 500px){
				display: flex;
				flex-direction: column;
				align-items: center;
			}
		}

		.containerInfoStatic {
			display: flex;
			align-items: baseline;
			padding-left: 70px;
			@media(max-width: 500px){
				justify-content: center;
			}
		}
		.blockInfo {
			font-size: 9px;
			color: #4a4a4a;

			.title {
				height: 13px;
				color: #4A4A4A;
				font-size: 9px;
				font-style: italic;
				font-weight: 600;
			}

			p.text {
				color: #000000;
				font-size: 14px;
				font-weight: 600;
				margin-top: 5px;
			}

			&.brand {
				flex-basis: 140px;
			}

			&.year {
				flex-basis: 150px;
				margin-left: 82px;
			}

			&.multibrand {
				@media(max-width: 500px){
					margin-top: 30px;
					margin-left: 0;
				}
			}
		}


	}

	.containerLink {
		margin: 21px 0 16px 0;

		a {
			margin: 0;
		}
	}

	.containerButtonText {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding-left: 74px;
	}

	p.textGrid1 {
		width: 550px;
		line-height: 26px;
		font-size: 14px;
		color: #4A4A4A;
	}
}

.top29 {
	margin-top: 29px;
}

@media (max-width: 820px) {
	.grid1 {
		&:after {
			content: "";
			display: block;
			width: 100%;
			// border: 1px solid red;
			height: 20px;
			box-sizing: border-box;
		}
		.contentLinkButton {
			width: auto;

			.linkShowCase {
				display: inline-block;
				margin: 0 0 15px 0;
				border: none;
				font-size: 12px;
				font-weight: 600;
				line-height: 22px;

				span {
					border-bottom: 1px solid $GREEN_LIGHT;
				}
			}

			.buttonShowCase {
				display: block;
			}
		}
		.leftGrid {
			float: none;
			padding: 0 10px;
			text-align: left;
			img {
				max-width: calc(100% - 20px);
				width: auto;
			}
		}
		.rightGrid {
			float:  none;
			max-width: 100%;
			width: auto;
			margin-bottom: 40px;
			.containerInfoStatic {
				padding-left: 10px;
				padding-right: 10px;

				.blockInfo {
					font-size: 9px;
					color: #4a4a4a;
					margin-left: 0px !important;
					flex-basis: auto !important;
					margin-right: 27px;

					.title {
						white-space: nowrap;
					}

					p.text {
						font-size: 12px;
						line-height: 18px;
					}

				}
			}
			.containerButtonText {
				display: block;
				padding-left: 30px;
				padding-right: 30px;
				text-align: center;
			}
			.containerLink {
				margin: 0 0 40px;
			}


			p.textGrid1 {
				width: auto;
				line-height: 26px;
				margin-bottom: 40px;
				text-align: left;
			}
		}
	}
}
