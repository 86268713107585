.container-change-pass{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100vw;
    height: 100vh;
    right: 0;
    top: 0;
    animation: on 0.5s ease-in-out;
    background-color: rgba(0, 0, 0, 0.59);
    z-index: 100000;

    @keyframes on {
        from {
          opacity: 0;
        }
      
        to {
         opacity: 1;
        }
    }

    .box-change-password{
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        width: 410px;
        height: 370px;
        text-align: center;
        position: relative;

        @media (max-width: 620px){    
            width: 90%;
        }

        & > h3{
            font-family: "PolySans-Slim";
            font-size: 26px;
            font-style: normal;
            font-weight: 300;
            line-height: 20px;
            margin: 37px;
        }

        .closed-box{
            background-color: #000;
            color: #fff;
            position: absolute;
            right: 0;
            top: 0;
            border-radius: 0 0 8px;
            padding: 3px 6px;
            cursor: pointer;
        }
    }

    p{
        font-family: "PolySans-Slim";
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
    }

    .error {
        font-size: 14px;
        color: red;    
    }

    .input-div {
        position: relative;
        display: flex;

        button {
            position: absolute;
            right: 5%;
            top: 40%;
            width: 1rem;
            height: 1rem;
            background-color: transparent;
        }

        input{
            width: 257px;
            height: 43px;
            border-radius: 5px;
            border: 1px solid #D4D4D4;
            margin-top: 8px;
            padding: 0.6rem;
        }
    }
   
}

.container-renew-pass__message-request{
    width: fit-content;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: red;
    animation: on 0.8s ease-in-out;

    @keyframes on {
        from {
          opacity: 0;
        }
      
        to {
         opacity: 1;
        }
    }

    & > img{
        margin-left: 4px;
        height: 19px;
    }
}