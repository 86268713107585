button {
    // width: 102px;
    // height: 31px;
    font-size: 12px;

    font-weight: normal;
    height: 40px;
    width: 130px;
    font-family: "PolySans-Slim";
    text-transform: uppercase;
    color: #FFF;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    background-color: #272626;
    border-radius: 30px;
    float: left;
    letter-spacing: 2px;

    &.unfilled {
        background-color: black;
        color: white;
    }

    &.large {
        width: 150px;
        height: 40px;
    }
}
