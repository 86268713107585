.brands-view-component {
    background-color: #f1edea;
}

.BrandsCollections {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.NoBrandsFound {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
    height: 50vh;

    font-family: 'PolySans-Slim';
    font-size: 26px;
    letter-spacing: 1px;
    line-height: 20px;
    color: #50545f;
    text-align: center;
}

.titleSection {
    margin-left: 0.65rem;
    font-size: 25px;
    text-transform: uppercase;
    color: #6f6f6f;
    
    @media (max-width: 1060px) {
        max-width: 400px;
        text-align: center;
    }
}

.dataDisplay {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 1060px) {
        align-items: center;
    }

    .azFilter {
        display: flex;
        gap: 1rem;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1.5rem;
        flex-wrap: wrap;
        padding: 0rem 2rem;
        justify-content: center;

        li {
            text-transform: uppercase;

            .false{
                all: unset;
                color: #000;
                cursor: pointer;
            }
            
            .true {
                all: unset;
                color: #6f6f6f70;
            }
        }
    }
}

.SeeAllBrands {
    all: unset;
    display: flex;
    cursor: pointer;
    padding: 1rem 1.5rem;
    border-radius: 5rem;
    background: #000;
    color: white;
    transition: 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    margin-bottom: 6rem;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;

    &:hover {
        background: #50545f;
    }
}

.componentCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loadingFilters{
    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);

    img{
        -webkit-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        to { transform: rotate(360deg); }
    }
}