.jumboTitle {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  padding: 4rem 0rem;
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.1));
  background-color: #ffffff;
  font-family: "";
  margin-bottom: 50px;
  z-index: 8;
  position: relative;

  @media(max-width: 820px) {
    padding: 4rem 0.5rem
  }

  &.jumbo-title--breadcomb {
    margin-bottom: 0;
  }

  &.jumbo-title--breadcomb-top {
    margin-top: 0;
  }

  .title {
    font-family: "PolySans-Slim";
    font-size: 36px;
    letter-spacing: 1px;
    line-height: 30px;
    color: #50545f;
    text-align: center;
    cursor: pointer;
  }
}
