@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: none; }

button {
  border: none;
  outline: none; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  font-family: "PolySans-Slim";
  background: #ffffff; }

@media (max-width: 820px) {
  html, body {
    max-width: 820px;
    width: 100%;
    overflow-x: hidden; } }

/* @media (max-width: 1366px) {
    %CENTER_COMPONENT {
        width: 90%;
        margin: 0 auto;
    }

    %CENTER_COMPONENT_SMALL {
        width: 80%;
        margin: 0 auto;
    }
} */
.variation-tooltip {
  color: #6d6d6d;
  font-weight: bold;
  box-shadow: 0px 2px 6px #99999940;
  border-radius: 5px;
  padding: 6px 10px; }
  @media (max-width: 820px) {
    .variation-tooltip {
      max-width: 50px; } }

.overlayProduct {
  width: 100%;
  height: 100%;
  z-index: 2;
  position: fixed;
  background: transparent;
  left: 0;
  top: 0; }

.products {
  width: 887px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  margin-bottom: 50px;
  min-height: 300px; }
  @media (max-width: 820px) {
    .products {
      width: 100%; } }
  .products .loading {
    margin-left: 92px; }
  .products .containerProducts {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border-left: 1px solid #979797;
    margin-top: 15px;
    padding-left: 14px;
    min-height: 300px;
    justify-content: flex-start; }
    @media (max-width: 820px) {
      .products .containerProducts {
        padding: 0;
        justify-content: space-around; } }
  .products .blocksProducts {
    width: 202px;
    min-height: 449px;
    margin-right: 13px;
    margin-bottom: 13px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    position: relative; }
    @media (max-width: 820px) {
      .products .blocksProducts {
        margin-right: 0;
        min-height: auto; } }
    @media (max-width: 500px) {
      .products .blocksProducts {
        width: 190px; } }
    @media (max-width: 360px) {
      .products .blocksProducts {
        width: 160px; } }
    @media (max-width: 320px) {
      .products .blocksProducts {
        width: 145px; } }
    .products .blocksProducts .checkBoxProduct {
      position: absolute;
      top: 5px;
      right: 10px;
      z-index: 2; }
    .products .blocksProducts .topBlockProducts {
      height: 266px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative; }
      .products .blocksProducts .topBlockProducts a {
        height: 100%;
        width: 100%; }
      .products .blocksProducts .topBlockProducts img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .products .blocksProducts .topBlockProducts .first-image-wrapper {
        height: 100%;
        display: flex;
        align-items: center; }
      .products .blocksProducts .topBlockProducts .hover-image-wrapper {
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: white; }
      .products .blocksProducts .topBlockProducts .hover-image {
        width: 100%;
        height: auto;
        object-fit: cover; }
      .products .blocksProducts .topBlockProducts:hover .hover-image-wrapper {
        opacity: 1; }
      .products .blocksProducts .topBlockProducts .image {
        cursor: pointer !important; }
        .products .blocksProducts .topBlockProducts .image > div {
          cursor: pointer !important; }
          .products .blocksProducts .topBlockProducts .image > div div {
            cursor: pointer !important;
            display: none; }
    .products .blocksProducts .mainProduct {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      color: #656565; }
      .products .blocksProducts .mainProduct .nameBrand {
        width: 80%;
        text-align: center;
        font-size: 11px;
        color: #656565;
        margin: 25px auto 10px auto;
        line-height: 16px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      .products .blocksProducts .mainProduct .nameBrandCollection {
        min-width: 50px;
        font-size: 12px;
        line-height: 20px;
        color: #656565;
        margin: 10px;
        text-transform: uppercase;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      .products .blocksProducts .mainProduct .nameProduct {
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 11px;
        color: #656565;
        margin: 10px 0; }
      .products .blocksProducts .mainProduct .variations {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0 0 17px 0;
        flex-wrap: wrap;
        padding: 0 10px; }
        .products .blocksProducts .mainProduct .variations li {
          width: 15px;
          border: 1px solid #ccc;
          height: 15px;
          margin: 10px 0 0 4px;
          background-size: cover;
          position: relative; }
          .products .blocksProducts .mainProduct .variations li .colorListNoTexture {
            position: absolute;
            display: block;
            top: 0;
            border-left: 15px solid transparent;
            border-bottom: 15px solid transparent; }
          .products .blocksProducts .mainProduct .variations li:first-child {
            margin-left: 0; }
      .products .blocksProducts .mainProduct .prices {
        font-size: 11px;
        color: #656565;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 14px; }
        .products .blocksProducts .mainProduct .prices .wrapper-single-price {
          display: flex;
          justify-content: center;
          width: 100%;
          margin-bottom: 5px; }
      .products .blocksProducts .mainProduct .buttonProduct {
        margin: 10px 0 20px 0;
        padding: 8px 20px;
        background: transparent;
        border: 2px solid #666;
        color: #666;
        font-size: 10px;
        cursor: pointer; }
      .products .blocksProducts .mainProduct .styled-block {
        border: none;
        cursor: not-allowed; }
      .products .blocksProducts .mainProduct .add-to-cart-btn {
        width: 80%; }
  .products .variationsProduct {
    width: 100%; }
    .products .variationsProduct .titleVariation {
      display: none; }
    .products .variationsProduct ul {
      width: 100%;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      justify-content: center;
      margin: 0 0 17px;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap; }
      .products .variationsProduct ul li {
        width: 15px;
        border: 1px solid #ccc;
        height: 15px;
        margin: 10px 0 0 4px;
        background-size: cover;
        position: relative;
        cursor: pointer; }
        .products .variationsProduct ul li .colorListNoTexture {
          position: absolute;
          display: block;
          bottom: 0;
          right: 0;
          border-left: 15px solid transparent;
          border-bottom: 15px solid transparent; }

.bold {
  font-weight: 800; }

.nonPricelistError {
  position: fixed;
  right: 5%;
  bottom: 3%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 0.65rem;
  padding: 1.5rem;
  transition: all 0.5s ease-in-out;
  transform: translateX(100%);
  overflow: hidden;
  z-index: 1000;
  opacity: 0; }
  .nonPricelistError p {
    color: rgba(0, 0, 0, 0.8);
    font-size: 0.9rem; }

.nonPricelistError.open {
  transform: translateX(0%);
  transition: all 0.5s ease-in-out;
  opacity: 1; }
