#main {
	background: #ffffff;
	@media (min-width: 1024px) {
		position: relative;
		// z-index: 9;
	}
}

#highlight {
	width: 100%;
	overflow: hidden;
}

.border-marker{
	box-shadow: 0 0 0 .15rem red;
	z-index: 99;
}

#sliderBrands {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
}

.contentBox .highlight {
	width: 560px;
	height: 100%;
	margin: 0 15px 0 0;
	box-sizing: border-box;
}

.contentBox .blockSlide {
	width: calc(100% - 580px);
}

#brandStyleFilters {
    margin: 74px auto 40px auto;

	.containerBrandFilter {
		width: 100%;
		display: flex;
		justify-content: space-between;

		.left {
			width: 610px;
		}

		.right {
            width: calc(100% - 680px);
		    display: flex;
		    flex-wrap: wrap;
			padding-left: 80px;

			.contentRight {
				width: 100%;
				margin: 0 auto;
			}
		}
	}
}

@media (max-width: 820px){

	#brandStyleFilters {
	    margin: 30px auto 40px auto;

		.containerBrandFilter {
			width: 100%;
			max-width: calc(100% - 60px);
			display: block;
			padding: 0 30px;

			.left {
				width: 100%;


				.brandStyle {
					p {
						text-align: left;
					}
				}
			}

			.right {
	            width: calc(100% + 40px);
			    display: block;

				padding: 0px;

				.contentRight {
					width: 100%;
					margin: 0 auto;
				}
			}
		}
	}

	#sliderBrands {
		width: 100%;
		display: block;
		overflow: hidden;
	}
}
