.box-card-address{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 462px;
    padding: 12px 10px 12px 20px;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    border-radius: 20px;
    border: 1px solid #D4D4D4;

    p{
        font-family: "PolySans-Slim", sans-serif;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: 540px){
            font-size: 14px;
        }
    }

    @media (max-width: 540px){
        width: 90%;
    }
}

.box-card-address__box-left, .box-card-address__box-rigth{
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-align: start;
    line-height: 20px;
}

.box-card-address__box-left__infos{
    width: 50%;
}

.box-card-address__box-left__btn-delete-address{
    all: unset;
    height: fit-content;
    cursor: pointer;
    transition: 400ms ease;

    &:hover{
        transform: translateY(-3px);
    }
}

.box-card-address__box-left__btn-edit-address{
    width: fit-content;
    height: fit-content;
    cursor: pointer;
    background-color: transparent;
    border: none;
    transform: translate(60px, 5px);
    position: relative;

    @media (max-width: 540px){
        transform: translate(0px, 0px);
    }

    & > img{
        width: 30px;
        height: fit-content;
    }

    &::after{
        content: 'edit address';
        position: absolute;
        right: 0;
        top: -31px;
        background: #fff;
        color: #000;
        border: 1px solid #000;
        opacity: 0;
        padding: 2px 7px;
        border-radius: 8px;
        font-size: 10px;
    }

    &:hover{
        transition: after 1s ease;

        &::after{
            opacity: 1;
        }
    }
}

.box-card-address__box-rigth{
    
    .box-card-address__box-rigth__infos{
        text-align: start;
        line-height: 20px;

        @media (max-width: 540px){
            font-size: 14px;
        }
    }

    .box-card-address__box-rigth__infos__box-icons{
        display: flex;

        img{

            & + img{
                margin-left: 2px;
            }
        }
    }
}

.add-address{
    width: 100%;
    height: 5rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    
    h3{
        position: absolute;
        left: 0;
        top: 0;
        font-weight: 500;
    }

    button{
        background-color: transparent;
        img {
            width: 2rem;
            height: 2rem;
        
            &:hover{
                opacity: 70%;
            }
        }
    }
}