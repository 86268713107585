.connection-filter {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .button {
    background: transparent;
    color: #b1b1b1;
    border: 2px solid #b1b1b1;
    font-size: 16px;

    &.button--active {
      color: #646464;
      border: 2px solid #646464;
    }
  }
}
