.boxBrands {
  width: 1153px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto 100px auto;

  @media(max-width: 1024px){
    width: 100%;
    justify-content: center;
  }

  &.boxBrands--small {
    width: 960px;
  }

  .containerImage {
    width: calc(25% - 20px);
    margin: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    p {
      width: 100%;
      text-align: center;
      margin: 10px 0;
      text-transform: uppercase;
      color: #656565;
      font-size: 14px;
    }

    a {
      color: #656565;
    }

    .blockBrand {
      width: 100%;
      height: 250px;
      margin: 10px 0;
      overflow: hidden;
      position: relative;
      cursor: pointer;
      background-repeat: no-repeat;
      background-size: auto;
      background-position: 50% 50%;
      background-color: #d8d8d8;
      border: 1px solid #ccc;
      box-sizing: border-box;

      div {
        width: 100%;
        height: 100%;
        background-color: white;
        opacity: 0.8;
        transition: opacity 0.1s linear;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #666;
        font-weight: bold;
      }

      a {
        width: 100%;
        height: 100%;
      }

      img {
        width: 100%;
        opacity: 0.1;
        transition: opacity 0.1s linear;
      }
      &:hover {
        img {
          opacity: 1;
        }
        div {
          opacity: 0;
        }
      }
      .hover {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        opacity: 0.8;
        background: #cccccc;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          opacity: 0.8;
          background: #cccccc;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &:hover {
        .hover {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 820px) {
  .boxBrands {
    width: calc(100% - 10px);
    padding: 0 5px;
    margin-bottom: 50px;

    .containerImage {
      width: calc(50% - 10px);
      margin: 5px;
      display: flex;
      height: auto;
      overflow: hidden;
      position: relative;

      .blockBrand {
        height: auto;
        width: 100%;
        overflow: hidden;
        position: relative;
        cursor: pointer;

        a {
          height: auto;
        }

        img {
          max-width: 100%;
        }
      }
    }
  }
}

.sellerView {
  .boxBrands {
    .containerImage {
      margin: 0 auto;
    }

    justify-content: center;
  }
}
