.__international {
	margin-top: 40px;
	padding: 20px;
	overflow: auto;
	font-size: 100%;
	text-align: center;
	background: #fbfbfb;
	font-size: 14px;
	font-family: "PolySans-Slim";
	line-height: 20px;

	table {
		border-collapse: separate;
		border-spacing: 1px;
		width:100%;
		tr td {
			border-top: 3px solid #fff;
			border-bottom: 3px solid #fff;
		}
	}

	table tr:hover td {
		border-top:3px solid #71cfc9 !important;
		border-bottom:3px solid #71cfc9 !important;
	}

	.__sizes {
		width: 100%;
		text-transform: uppercase;
		color: #fff;
		background: #6d6d6d;
		transition: background-color 0.05s linear;

		td {
			padding: 5px 15px;
		}
	}

	.__measurements {
		padding: 5px 15px;
		color: #fff;
		background: #6d6d6d;
		transition: background-color 0.05s linear;
	}
	.__values {
		padding: 5px 15px;
	}
}
