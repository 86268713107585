.buttonNoBg{
    display: flex;
    height: 40px;
    padding: 0px 50px;
    width: fit-content;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-radius: 20px;
    border: 1px solid #000;
    color: #000;
    font-family: "PolySans-Slim", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    text-transform: uppercase;

    @media (max-width: 768px){    
        font-size: 10px;
        width: fit-content;
    }
}