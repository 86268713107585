.addToCart {
	margin-bottom: 15px;
    padding: 10px 0;
    width: 100%;
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
    border: 0;
    background: #000000;
	text-align: center;
    letter-spacing: 2px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    cursor: pointer;

    &:disabled {
    cursor: not-allowed;
    background: transparent;
    border: 2px solid #666;
    color: #666;
    }
}
