@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: none; }

button {
  border: none;
  outline: none; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  font-family: "PolySans-Slim";
  background: #ffffff; }

@media (max-width: 820px) {
  html, body {
    max-width: 820px;
    width: 100%;
    overflow-x: hidden; } }

/* @media (max-width: 1366px) {
    %CENTER_COMPONENT {
        width: 90%;
        margin: 0 auto;
    }

    %CENTER_COMPONENT_SMALL {
        width: 80%;
        margin: 0 auto;
    }
} */
.variation-tooltip {
  color: #6d6d6d;
  font-weight: bold;
  box-shadow: 0px 2px 6px #99999940;
  border-radius: 5px;
  padding: 6px 10px; }
  @media (max-width: 820px) {
    .variation-tooltip {
      max-width: 50px; } }

#callToAction {
  width: 100%;
  height: 305px;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  position: relative; }
  #callToAction .textCallToAction {
    position: relative;
    z-index: 2;
    height: 100%;
    color: #ffffff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center; }
    #callToAction .textCallToAction .button-container {
      display: flex;
      justify-content: center;
      align-items: center; }
    #callToAction .textCallToAction > div h2 {
      width: 470px;
      margin: 0 auto;
      font-size: 40px;
      max-width: 100vw; }
      @media (max-width: 500px) {
        #callToAction .textCallToAction > div h2 {
          font-size: 24px; } }
    #callToAction .textCallToAction > div p {
      margin: 30px auto;
      width: 470px;
      max-width: 100vw;
      text-shadow: 0 2px 1px rgba(0, 0, 0, 0.6);
      font-size: 16px;
      line-height: 22px; }
      @media (max-width: 500px) {
        #callToAction .textCallToAction > div p {
          padding: 0 10px; } }
  @media (max-width: 820px) {
    #callToAction {
      height: 100vw;
      background-size: 100%; }
      #callToAction .button {
        min-height: 34px;
        height: fit-content; } }

.callToAction--overlay:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000000;
  opacity: 0.5;
  z-index: 1; }
