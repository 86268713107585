.containerInfo {
	width: 702px;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	box-sizing: border-box;
	padding-top: 55px;

	@media(max-width: 500px){
		width: 100%;
		flex-direction: column;
		align-items: center;
	}

	.blockInfo {
		width: 175px;
		font-size: 9px;
	    color: #4a4a4a;
		box-sizing: border-box;
		margin-bottom: 28px;

		.title {
			color: #4A4A4A;
			font-size: 9px;
			font-style: italic;
			font-weight: 600;
			line-height: 13px;
			margin-bottom: 5px;
		}

		p.text {
			color: #000000;
			font-size: 14px;
			font-weight: 600;
			line-height: 19px;
			width: 154px;
		}
	}
}