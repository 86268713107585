.relative {
    position: relative;
    width: 220px;
    height: 35px;
}

.brands-view-filter {
    display: flex;
    justify-content: space-between;
    margin-top: 45px;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1280px;
    align-items: center;

    @media (max-width: 1279px) {
        justify-content: center;
        padding: 2rem 0rem;
        max-width: 700px;
        gap: 1.5rem;
        margin-top: 0px;
    }

    button.active {
        background: #7c7f88;
        color: white;
        border: 0px;
    }
    button {
        background: white;
        width: 72px;
        height: 35px;
        border: 1px solid #7c7f8870;
        color: #61656f;

        &:hover {
            background: #7c7f88;
            color: white;
            border: 0px;
        }
    }
}

.brandSearch {
    all: unset;
    font-family: 'PolySans-Slim';
    border-bottom: 1px solid #7c7f8870;
    padding: 0.5rem 0rem;
    width: 200px;

    &::placeholder {
        color: #7c7f8870;
    }
}

.lupa {
    position: absolute;
    right: 25px;
    bottom: 12px;
    width: 15px;
    height: 15px;

    svg {
        width: 100%;
        height: 100%;
        fill: #7c7f8870;
    }
}

.list {
    position: absolute;
    font-family: 'PolySans-Slim';
    background: white;
    padding: 0.4rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    min-width: 190px;

    li {
        color: #7c7f8870;
        cursor: pointer;
        padding: 0.5rem 0.5rem;
        border-radius: 8px;

        &:hover {
            color: black;
            background: #7c7f8820;
        }

        .brand--item--list {
            color: #000;
        }
    }
}

.lastOptions {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    font-family: 'PolySans-Slim';
    gap: 0.2rem;

    p {
        color: #43a2f1;
        text-decoration: underline;
        cursor: pointer;
        font-size: 11px;
        cursor: pointer;
    }

    .ActivateCollectionsOnly {
        display: flex;
        gap: 0.2rem;
        cursor: pointer;

        .switch {
            position: relative;
            display: inline-block;
            width: 28px;
            height: 15px;
        }

        .switch input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: 0.4s;
            transition: 0.4s;
        }

        .slider:before {
            position: absolute;
            content: '';
            height: 11px;
            width: 11px;
            left: 2px;
            bottom: 2px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
        }

        input:checked + .slider {
            background-color: #2196f3;
        }

        input:focus + .slider {
            box-shadow: 0 0 1px #2196f3;
        }

        input:checked + .slider:before {
            -webkit-transform: translateX(13px);
            -ms-transform: translateX(13px);
            transform: translateX(13px);
        }

        .slider.round {
            border-radius: 34px;
        }

        .slider.round:before {
            border-radius: 50%;
        }

        label {
            cursor: pointer;
            font-size: 14px;
            vertical-align: baseline;
        }
    }
}
