nav#navLanding {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 10;
  background-color: white;
  top: 0;
  @media (max-width: 820px) {
    height: 58px;
    box-shadow: none;
  }

  .logo {
    display: flex;
    justify-content: cecnter;
    align-items: center;
    width: 290px;
    height: 36px;
    position: absolute;
    left: 45%;
    top: 50%;
    margin: -18px 0 0 -67px;

    span {
      font-weight: 500;
      font-size: 1.2em;
      color: #000;
      font-family: "PolySans-Slim", sans-serif;
    }

    a {
      width: 134px;
      font-size: 21px;
    }

    img {
      width: 100%;
    }
    @media (max-width: 820px) {
      display: none;
    }

    &.logo--powered-by {
      left: 50%;
      span {
        font-size: 0.7em;
      }

      img {
        width: 70%;
      }
    }
  }

  .translate-options {
    margin-left: 60px;
    color: #d8d8d8;
    display: flex;
    align-items: center;
    @media (max-width: 500px) {
      display: none;
    }

    .goBack {
      font-size: 11px;
      font-weight: 600;
      color: #000;
      border-bottom: 2px solid #000;
      height: 15px;
      line-height: 15px;
      margin-left: 10px;

      &:hover {
        color: #d8d8d8;
        border-bottom: 2px solid #d8d8d8;
      }
    }

    a {
      cursor: pointer;
      padding-bottom: 3px;
      color: #d8d8d8;

      &:visited {
        color: #d8d8d8;
      }
      &:active {
        color: #666666;
        border-bottom: 2px solid #71cfc9;
      }
      &.active {
        color: #666666;
        border-bottom: 2px solid #71cfc9;
      }
    }
    a {
      margin: 0 5px 0 5px;
    }
  }

  .itens {
    display: flex;
    justify-content: flex-start;
    margin-left: 65px;
    font-family: "PolySans-Slim";

    @media (max-width: 820px) {
      display: none;
      margin-left: 0;
    }

    ul {
      list-style: none;
      display: flex;

      li {
        font-size: 15px;
        margin-right: 2em;
        cursor: pointer;
        display: flex;
        align-items: center;

        a {
          color: #a7a9af;
        }

        a:hover {
          color: #50545f;
        }

        a.selected {
          color: #50545f;
        }
      }
    }

    &.showMenu {
      display: flex;
      position: absolute;
      width: 100%;
      top: 0;
      background: #fff;
      justify-content: center;
      align-items: center;
      ul {
        height: 100vh;
        width: 100%;
        padding-top: 100px;
        flex-direction: column;
        li {
          padding: 20px 0;
          justify-content: center;
        }
      }
    }
  }

  .login-button {
    justify-self: flex-end;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;

    @media (max-width: 820px) {
      display: none;
    }

    .person-login {
      margin-right: 5px;
      padding: 20px;
      z-index: 12;

      &:hover {
        .dropdown {
          display: flex;
        }
      }
    }

    .dropdown {
      display: none;
    }

    .dropdown:hover {
      display: flex;
    }

    &:hover {
      .dropdown {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 50px;
        right: 2px;
        background-image: url('/images/landing/header/dropdown.png');
        width: 263px;
        height: 146px;
        z-index: 11;

        font-family: 'PolySans-Slim';
        color: #a7a9af;

        li:first-child {
          margin-top: 12px;
          border-bottom: 1px solid #D9D9D9;
        }

        li {
          padding: 22px 0;
          text-align: center;
          cursor: pointer;
          width: 200px;
        }
      }
    }
  }

  .MenuResponsive {
    display: none;
    width: 100%;
    height: 58px;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    z-index: 100;
    box-shadow: -3px 0px 3px 0px;
    @media (max-width: 820px) {
      display: flex;
    }
    .logo-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-basis: 100%;
      text-align: center;
      img {
        height: 50px;
      }

      &.logo-wrapper--xAccount {
        justify-content: flex-start;
        .logo-desk {
          display: block;
          margin-left: 0;
        }
      }

      &.logo--xAccount {
        justify-content: flex-start;
        span {
          font-size: 0.6em;
        }

        img {
          height: 30px;
        }
      }

      @media (max-width: 320px) {
        &.logo--xAccount {
          display: none;
        }
      }
    }
    
    .logo.logo--powered-by {
      left: 50%;
      span {
        font-size: 0.7em;
      }

      img {
        width: 70%;
      }
    }
    .open-menu-wrapper {
      flex-basis: 33%;
      .openMenuMobile {
        display: block;
        width: 25px;
        cursor: pointer;
        padding: 10px;

        span {
          display: block;
          width: 100%;
          height: 3px;
          background-color: #666;
          margin-bottom: 3px;
        }
      }
    }
    .login-button-wrapper {
      flex-basis: 33%;
      text-align: right;
      button {
        width: 70px;
        height: 23px;
        border-radius: 8px;
        font-size: 10px;
        letter-spacing: -0.01px;
        font-weight: 600;
      }
    }
  }

  .logo-wrapper {
    .logo-desk {
      width: 152px;
      margin-left: 20px;
      height: 50px;
      object-fit: contain;

      @media (max-width: 820px) {
        display: none;
      }

      img {
        width: 152px;
      }
    }
  }
}

#shadowNav {
  position: fixed;
  top: 60px;
  width: 100%;
  height: 10px;
  z-index: 9;
  background: transparent linear-gradient(180deg, #a8a8a833 0%, #ffffff00 100%)
    0% 0% no-repeat padding-box;
}
