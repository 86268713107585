@import "../../../shared/style/Base.scss";

#our-platform {
    margin: 0 auto;
    padding: 50px 100px !important;
    display: flex;
    background: #F1EDEA;

    @media(max-width: 820px){
      flex-direction: column;
      padding: 50px !important;
    }

    .left{
      width: 30%;
      padding-right: 15px;

      @media(max-width: 820px){
        width: 100%;
      }

      .title{
        margin-bottom: 20px;
        font-family: $FONT-POLY;
        font-size: 28px;
        color: #50545F;
        text-align: left;
      }

      .description{
        width: 100% !important;
        padding: 0 !important;
        word-wrap: break-word;
        text-align: left;
        margin-bottom: 50px !important;
        @media (max-width: 820px){
          font-size: 14px;
        }
      }

      .buttons{
        button{
          height: 40px;
          width: 130px;
          font-family: $FONT-POLY;
          text-transform: uppercase;
          color: #FFF;
          cursor: pointer;
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-align-items: center;
          align-items: center;
          background-color: #272626;
          border-radius: 30px;
          float: left;
          margin-right: 20px;
          margin-top: 5px;

          @media(max-width: 820px){
            margin-right: 10px;
          }
    
        }
      }

    }

    .right{
      width: 70%;
      display: flex;
      justify-content: center;

      @media(max-width: 820px){
        width: 100%;
      }

      
      img{
        max-width: 100%;
        align-self: flex-start;

        @media(max-width: 820px){
          margin-top: 30px;
        }
  
      }
    }

    .legends-container {
        padding: 100px 40px 81px 40px;
        height: 356px;
        color: #FFFFFF;
        background-size: cover;
        background-repeat: no-repeat;
        background-blend-mode: saturation;
        margin-bottom: 60px;
        @media (max-width: 820px){
          height: auto;
          padding: 60px 20px 90px 20px;
          background-position: right;
        }
        .legends {
          margin: 50px 0;
          h1 {
            margin-top: 40px;
            color: #000;
          }
          >div {
            background-color: rgba(255,255,255,.9);
            border-radius: 50%;
            width: 280px;
            height: 280px;
            margin: 0px 20px;
            border: 2px solid #71CFC9;
            text-align: center;
            
            >p {
              display: table;
              height: 140px;
              padding: 0px 20px;
              
              >span {
                display: table-cell;
                vertical-align: middle;
              }
            }
          }
        }
    }
    .watch-the-video {
        padding: 120px 120px 81px 120px;
        height: 356px;
        color: #FFFFFF;
        background-size: cover;
        background-repeat: no-repeat;
        background-blend-mode: saturation;
        @media (max-width: 820px){
          height: auto;
          padding: 60px 20px 90px 20px;
          background-position: right;
        }
        h1 {
            color: white;
            font-size: 18px;
            margin-bottom: 90px;
            font-weight: 600;
            @extend %Montserrat;
            @media (max-width: 820px){
              font-size: 18px;
              margin-bottom: 70px;
            }
        }
        span{
          font-family: "PolySans-Slim";
          font-weight: 300;
          font-size: 14px;
        }
        >div {
            font-size: 0.8em;
            display: grid;
            grid-template-columns: 50% 50%;
            h1{
              @extend %Montserrat;
              font-size: 14px;
              font-weight: 400;
              @media (max-width: 820px){
                font-size: 14px;
              }
            }
            @media (max-width: 820px){
              font-size: 14px;
              display: flex;
              flex-direction: column;
            }
            > :nth-child(1) {

                border-right: 1px solid;
                @media (max-width: 820px){
                  position: relative;
                  border-right: 0;
                  margin-bottom: 50px;
                  &::after{
                    content: "";
                    width: 92px;
                    height: 1px;
                    margin-top: 50px;
                    background: #FFF;
                  }
                }
            }

            >div {
                display: flex;
                flex-direction: column;
                text-align: -webkit-center;

                @media (max-width: 820px){
                  font-size: 14px;
                }
                h1 {
                    margin: 0;
                }

                img {
                    cursor: pointer;
                    width: 17px;
                    margin: 0 auto;
                    margin-top: 10%;
                    margin-bottom: 10%;
                    @media (max-width: 820px){
                      margin: 20px 0;
                    }
                }
            }
        }
    }
    .how-it-works-iframe{
      width: 700px;
      height: 350px;
      @media (max-width: 820px){
       width: 100%;
      }
    }

    .legends {
        margin: 65px 0;
        display: grid;
        grid-template-columns: 33.3% 33.3% 33.3%;
        @media (max-width: 820px){
          display: block;
          margin: 0 20px;
        }
        .slide{
          @media (max-width: 820px){
            margin-top: -40px;
          }
        }
        #title-and-description{
          @media (max-width: 820px){
            // padding: 50px 18px;
            margin: 20px;
            // background: #FFF;
            // box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.31);
          }
        }
        h1 {
            font-size: 1.5em;
            margin-bottom: 7%;
            text-transform: uppercase;
            @media (max-width: 820px){
              font-size: 21px;
            }
        }

        p {
            color: #666666;
            line-height: 20px;
            font-size: 14px;
            @media (max-width: 820px){
              margin: 0;
            }
        }
        .box-legend{
          margin-top: 26px;
          @media (max-width: 820px){
            font-size: 14px;
          }
        }
        .slick-arrow{
          width: 8px;
          height: 17px;
          background-size: 8px 17px;
        }
        .slick-next{
          right: -8px;
          &::before{
            content: '';
          }
        }
        .slick-prev{
          left: -8px;
          &::before{
            content: '';
          }
        }
        .slick-dots{

          li{
            button:before{
              font-size: 11px;
              color: #48a797;
            }
          }
        }
    }
    .join-us-btn{
      .unfilled, .register{
        box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
        display: block;
        font-size: 13px;
        margin: 0 auto;
        background: transparent;
        width: 160px;
        padding: 0 20px;
        height: 44px;
        border: 2px solid #71CFC9;
        line-height: 44px;
        border-radius: 8px;
        color: #71CFC9;
      }
	  @media (max-width: 820px) {
		  margin-top: 85px;
	  }
    }
}
