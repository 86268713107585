.container-change-pass {
    width: 100vw;
    height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.59);

  .box {
    background-color: #fff;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 2rem 5rem;

    @media (max-width: 550px){
        padding: 2rem;
    }


    & > h3 {
      font-family: "PolySans-Slim";
      font-size: 26px;
      font-style: normal;
      font-weight: 300;
      line-height: 20px;
    }

    .input {
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
      margin-top: 1rem;

      p {
        font-family: "PolySans-Slim";
        font-size: 1rem;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
      }

      small {
        color: red;
      }

      input {
        border-radius: 5px;
        border: 1px solid #d4d4d4;
        width: 18rem;
        height: 2rem;
        padding: 0 0.5rem;
      }

      .input-box{
        display: flex;
        align-items: center;

        position: relative;
        width: 100%;

        button {
          width: fit-content;
          height: fit-content;

          background-color: transparent;
          outline: none;
          border: none;
          box-shadow: none;
          position: absolute;
          right: 3%;
          padding: 0.5rem;
        }

        img {
          width: 1rem;
          height: 1rem;
        }
      }
    }

    .closed-box {
      background-color: #000;
      color: #fff;
      position: absolute;
      right: 0;
      top: 0;
      border-radius: 0 0 8px;
      padding: 3px 6px;
      cursor: pointer;
    }
  }
}

.container-renew-pass__message-request {
  width: fit-content;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
  animation: on 0.8s ease-in-out;
  white-space: nowrap;

  & > img {
    height: 16px;
  }
}
