@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: none; }

button {
  border: none;
  outline: none; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  font-family: "PolySans-Slim";
  background: #ffffff; }

@media (max-width: 820px) {
  html, body {
    max-width: 820px;
    width: 100%;
    overflow-x: hidden; } }

/* @media (max-width: 1366px) {
    %CENTER_COMPONENT {
        width: 90%;
        margin: 0 auto;
    }

    %CENTER_COMPONENT_SMALL {
        width: 80%;
        margin: 0 auto;
    }
} */
#our-brands-items .slick-slider {
  width: calc(100% + 10px);
  margin: 0 -5px;
  position: unset;
  padding: 26px;
  overflow: hidden;
  padding-bottom: 250px;
  margin-bottom: -250px; }

#our-brands-items .slick-list {
  overflow: visible;
  position: relative; }
  #our-brands-items .slick-list::before, #our-brands-items .slick-list::after {
    content: "";
    height: 100%;
    width: 25px;
    z-index: 10;
    top: 0;
    position: absolute;
    background: #fff; }
  #our-brands-items .slick-list::before {
    left: -26px; }
  #our-brands-items .slick-list::after {
    right: -26px; }

#our-brands-items .slick-slide {
  position: relative; }

#our-brands-items .slick-track {
  position: relative; }

#our-brands-items .brand {
  position: relative; }
  @media (max-width: 820px) {
    #our-brands-items .brand {
      height: 150px;
      cursor: "pointer"; } }
  #our-brands-items .brand .containerImage {
    width: 100%;
    height: 306px;
    background-color: white;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
    @media (max-width: 1600px) {
      #our-brands-items .brand .containerImage {
        height: 256px; } }
    @media (max-width: 1440px) {
      #our-brands-items .brand .containerImage {
        height: 228px; } }
    @media (max-width: 1280px) {
      #our-brands-items .brand .containerImage {
        height: 198px; } }
    @media (max-width: 1280px) {
      #our-brands-items .brand .containerImage {
        height: 198px; } }
    @media (max-width: 1024px) {
      #our-brands-items .brand .containerImage {
        height: 168px; } }
    @media (max-width: 910px) {
      #our-brands-items .brand .containerImage {
        height: 144px; } }
    @media (max-width: 820px) {
      #our-brands-items .brand .containerImage {
        height: 150px; } }
    @media (max-width: 380px) {
      #our-brands-items .brand .containerImage {
        width: 107px; } }
  #our-brands-items .brand .brand-tag-container {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 13; }
    #our-brands-items .brand .brand-tag-container .brand-image-tag {
      position: relative;
      float: right;
      width: auto;
      height: auto; }
      #our-brands-items .brand .brand-tag-container .brand-image-tag.brand-image-tag--hide {
        display: none; }
  #our-brands-items .brand .brand-image-wrapper {
    border: 0 solid #000000;
    background-color: #fff;
    width: 100%;
    height: 100%; }
  #our-brands-items .brand .brand-footer {
    display: none;
    transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    z-index: 2;
    font-family: "PolySans-Slim";
    flex-direction: column;
    text-align: center;
    margin-top: auto; }
    #our-brands-items .brand .brand-footer h2 {
      color: #000000;
      font-size: 17px;
      font-weight: bold;
      letter-spacing: 4.2px;
      line-height: 23px;
      width: calc(100% - 40px);
      margin: 0 20px;
      padding: 13px 0 16px 0; }
    #our-brands-items .brand .brand-footer .text-hover {
      color: #787878;
      font-family: "PolySans-Slim";
      font-size: 14px;
      line-height: 21px; }
    #our-brands-items .brand .brand-footer .subtitle {
      text-align: center;
      height: 36px;
      width: 236px;
      color: #787878;
      font-size: 13px;
      font-weight: bold;
      line-height: 18px;
      text-align: center; }
    #our-brands-items .brand .brand-footer .brand-tags {
      color: #71cfc9;
      font-size: 13px;
      font-weight: bold;
      line-height: 18px;
      margin: 10px 0 20px 0; }
      #our-brands-items .brand .brand-footer .brand-tags span {
        margin-right: 5px; }
  #our-brands-items .brand button {
    height: 31px;
    width: 113px;
    font-size: 12px;
    font-weight: 800;
    margin: 0 auto; }
  @media (min-width: 820px) {
    #our-brands-items .brand:hover {
      z-index: 14; }
      #our-brands-items .brand:hover .brand-footer {
        display: flex; }
      #our-brands-items .brand:hover .brand-image-wrapper {
        padding: 23px 30px;
        margin: -23px -23px -23px -30px;
        background: #fff;
        position: absolute;
        height: auto;
        box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.5), 0 1px 4px 0 rgba(0, 0, 0, 0.3); }
      #our-brands-items .brand:hover .brand-image-tag {
        display: none; } }
