#UpdateAddressModal {
  .modal-title {
    line-height: 1.42857;
    font-size: 24px;
    font-family: "Polysans-Slim", sans-serif;
    text-align: center;
    color: #333;
    font-weight: 500;
    margin-top: 0px;
  }

  .copy-from-billing {
    color: #a0a0a0;
    cursor: pointer;
    font-size: 10px;
    text-transform: lowercase;
    text-decoration: underline;
    padding: 25px 0 0px;
  }

  .text-container {
    width: 400px;
    margin: 0 auto 20px auto;
    h4 {
      color: #cbcbcb;
      font-size: 14px;
      display: inline-block;
      padding: 20px 0 10px;
      position: relative;
      font-weight: 700;
    }
    label {
      font-size: 11px;
    }

    input {
      padding-left: 15px;
      font-size: 12px;
      border-radius: 0;
      padding-top: 7px;
      padding-bottom: 7px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      box-sizing: border-box;
      margin-top: 3px !important;
      margin-bottom: 6px !important;
      width: 100%;
      border: 1px solid silver;
    }
  }
}
