.messages-chat-block-component {
  display: flex;
  margin: 15px;

  .time {
    display: flex;
    font-size: 15px;
    color: #000000;
    margin-right: 35px;
    margin-top: 10px;
    width: 100px;
    min-width: 100px;
    justify-self: flex-start;
  }

  .message {
    background-color: white;
    padding: 15px;
    border-radius: 4px;
    max-width: 430px;
    position: relative;
    cursor: pointer;
    -webkit-transition: background-color 0.5s ease-out;
    -moz-transition: background-color 0.5s ease-out;
    -o-transition: background-color 0.5s ease-out;
    transition: background-color 0.5s ease-out;

    .flag {
      display: none;
    }

    &.message--flagged, &:hover {
      background-color: #66dad1;
      color: white;

      .flag {
        display: block;
        margin-bottom: 5px;
      }
  
      &::before {
        border-right: 10px solid #66dad1;
      }
    }

    &::before {
      position: absolute;
      content: "";
      top: 10px;
      left: -10px;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid white;
      -webkit-transition: all 0.5s ease-out;
      -moz-transition: all 0.5s ease-out;
      -o-transition: all 0.5s ease-out;
      transition: all 0.5s ease-out;
    }

    .anexo {
      margin-top: 20px;
      cursor: pointer;

      .image-file {
        max-width: 250px;
        max-height: 150px;
      }
    }
  }

  &.messages-chat-block-component--right {
    flex-direction: row-reverse;

    .time {
      margin-left: 35px;
      justify-content: flex-end;
    }

    .message {
      background-color: #666666;
      color: #ffffff;

      &.message--flagged, &:hover {
        background-color: #66dad1;
        color: white;
        
        &::after {
          border-left: 10px solid #66dad1;
        }
      }

      &::before {
        display: none;
      }

      &::after {
        position: absolute;
        content: "";
        right: -10px;
        top: 10px;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid #666666;
        -webkit-transition: all 0.5s ease-out;
        -moz-transition: all 0.5s ease-out;
        -o-transition: all 0.5s ease-out;
        transition: all 0.5s ease-out;
      }
    }
  }
}
