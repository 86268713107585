@import "../../../presentation/shared/style/Base";

.modal-import{
  h1{
    font-size: 1.5rem !important;
  }
  
  .modal-content{
    margin-top: 50px;
    text-align: center;

    h2{
      font-size: 1.2rem !important;
      margin: 15px 0;
      color: #000 !important;
    }

    h3{
      font-size: 1rem !important;
      color: #000 !important;
    }

    .file-upload-wrapper {
      $defaultColor: #272626;
      $height: 60px;
      
      position: relative;
      width: 100%;
      height: $height;
      
      &:after {
        content: attr(data-text);
        font-size: 18px;
        position: absolute;
        top: 0;
        left: 0;
        background: #f2f2f2;
        padding: 10px 15px;
        display: block;
        width: calc(100% - 40px);
        pointer-events: none;
        z-index: 20;
        height: $height - 20px;
        line-height: $height - 20px;
        color: #999;
        border-radius: 5px 10px 10px 5px;
        font-weight: 300;
      }
      
      &:before {
        content: 'Upload';
        position: absolute;
        top: 0;
        right: 0;
        display: inline-block;
        height: 60px;
        background: $defaultColor;
        color: #fff;
        font-weight: 300;
        z-index: 25;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: $height;
        padding: 0 15px;
        text-transform: uppercase;
        pointer-events: none;
        border-radius: 0 5px 5px 0;
      }
      
      &:hover {
        &:before {
          background: darken($defaultColor, 10%);
        }
      }
       input {
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 99;
        height: $height - 20px;
        margin: 0;
        padding: 0;
        display: block;
        cursor: pointer;
        width: 100%;
      }
    }

    .btn-import{
      background-color: #000 !important;
      margin-top: 30px;
      width: 100%;
      font-size: 1rem;
      letter-spacing: 4px;

      &:disabled{
        opacity: 0.4;
      }
    }
  }
}

.modal-go-to-cart{
  h1{
    font-size: 1.5rem !important;
    text-transform: initial !important;
  }
  
  .modal-content{
    margin-top: 30px;
    text-align: center;


    .btn-go-to-cart{
      background-color: #000 !important;
      margin-top: 30px;
      width: 100%;
      font-size: 1rem;
      letter-spacing: 4px;
    }
  }
}

.modal-replace{
  h2{
    font-size: 1.3rem !important;
    color: #000 !important;
    text-transform: initial !important;
  }
  
  .modal-content{
    margin-top: 20px;
    text-align: center;

    h2{
      font-size: 1.5rem !important;
      margin: 15px 0;
      color: #000 !important;
    }

    .replace-buttons{
      width: 100%;
      display: grid;
      grid-template-columns: auto auto;
      gap: 1rem;
    }

    .btn-replace{
      background-color: #000 !important;
      margin-top: 30px;
      width: 100%;
      font-size: .8rem;
      letter-spacing: 4px;

      &:disabled{
        opacity: 0.4;
      }
    }
    .btn-add{
      background-color: #6D6E6E !important;
      margin-top: 30px;
      width: 100%;
      font-size: .8rem;
      letter-spacing: 4px;

      &:disabled{
        opacity: 0.4;
      }
    }
  }
}

#menuCollection {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: .5rem;
  margin-bottom: 25px;
  // margin-left: 7.5%;
  // flex-wrap: wrap;

  &.justify-center {
    justify-content: center;
    margin-left: 0;
    max-width: 887px;
  }

  @media (max-width: 820px) {
    width: calc(100% - 2rem);
    justify-content: center;
    padding: 0 1rem;
    // align-items: center;
    // margin: 10px 0;
  }
  
  li {
    @extend %BUTTON_BLACK;
    font-size: 9px;
    letter-spacing: 1px;
    line-height: 20px;
    width: initial;
    // padding: 0 20px;
    height: 28px;
    margin: 0;

    &:first-child {
      margin-left: 0;
    }

    @media (max-width: 820px) {
      // margin: 25px;
      margin-top: 0px;

      &:first-child {
        // margin: 30px;
      }
    }

    a {
      color: #ffffff;
    }
  }
}
