@import "../../../shared/style/Base";

.register-lh-modal{
  .lh-modal-container {
    max-height: 80%;
    height: fit-content;
  }
}
#register-modal {
  margin-bottom: 30px;
  .title-and-description {
    text-align: center;
    padding-bottom: 5px;

    h2 {
      color: #000;
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 25px;
    }

    .description {
      padding: 0 20px;
      line-height: 17px;
      font-size: 14px;
      color: #666;
      text-transform: uppercase;
    }
  }

  .registration-title-box {
    text-align: center;
    width: 100%;
    font-size: 14px;
    color: #666666;
    font-weight: bold;
    font-family: $FONT;
  }

  span.subtitle {
    color: #cbcbcb;
    font-size: 14px;
    display: inline-block;
    padding: 20px 0 10px 0;
    position: relative;
    font-weight: bold;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 50px;
    .success-text {
      display: flex;
      align-items: center;
      margin: 40px 0px;

      svg {
        color: #87cec9;
      }
      span {
        margin: 20px 0px;
        color: #919191;
        font-size: 14px;
      }
    }

    .company-info, .contact-info {
      width: 100%;
    }

    .contact-info {
      .email-box {

        span {
          font-size: 8px;
          padding: 6px;
          margin-top: 4px;
          line-height: 13px;
          color: #d95c5c;
        }
      }
    }

    > div {
      text-align: center;
      display: flex;
      flex-direction: column;
    }

    select {
      width: 100%;
    }

    input, select {

      padding-left: 15px;
      font-size: 12px;
      border-radius: 0px;
      padding-top: 7px;
      padding-bottom: 7px;
      -webkit-appearance: none;
      appearance: none;
      box-sizing: border-box;
      margin-top: 6px!important;
      width: 100%;
      border: 2px solid #c0c0c0;
    }

    .add-location {
      cursor: pointer;
      display: flex;
      text-align: left;
      align-items: center;
      margin-top: 10px;

      svg {
        margin-right: 3px;
        color: #000000;
      }

      span {
        color: #888;
        font-size: 11px;
      }
    }

    .auto-complete-group {
      display: flex;
      align-items: center;

      svg {
        margin-left: 3px;
        cursor: pointer;
        color: #87cec9;
      }
    }
  }

  button {
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
  }

  .checkbox-group {
    display: flex;
    flex-direction: row !important;
    align-items: flex-end;
    padding: 20px 0;
    input {
      margin-right: 10px;
      width: auto;
    }
    label {
      font-size: 12px;
      color: #c0c0c0;
    }
  }

  .receive-news-checkbox {
    display: block;
    line-height: 20px;
    text-transform: uppercase;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 12px;
    color: #c0c0c0;
    user-select: none;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    &:hover{
      input ~ .checkmark{
        background-color: #ccc;
      }
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: #eee;
      &:after {
        left: 7px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }


  .receive-news-checkbox input:checked ~ .checkmark {
    background-color: black;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .receive-news-checkbox input:checked ~ .checkmark:after {
    display: block;
  }

  .register-button {
    @extend %BUTTON_BLACK;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .react-select__placeholder {
    color: #757575;
    font-size: 12px;
    font-family: 'Polysans-Slim';
    font-weight: 400;
    padding-left: 8px;
  }

  .react-select__control {
    background-color: rgba(239, 239, 239, 0.3);
    border: 2px solid silver;
  }
}

.email-box {
  margin-top: 20px;

  p {
    font-size: 10px;
    color: #d95c5c;
    text-align: left;
    margin-bottom: 15px;
    font-weight: bold;
  }
}
