:local(.container) {
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;
    padding-top: 50px
}
:local(.containerComponent) {
    margin-bottom: 20px;
    background-color: #ffffff;
    border-radius: 10px;

    .titleComponent {
        border-bottom: 1px solid #dcdcdc;
        padding: 15px 30px;
        font-size: 26px;
    }
    .content {
        padding: 15px 30px;
    }
    .contentCode {
        background-color: #dcdcdc;
        padding: 15px 30px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
}