.container-profile{
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: normal;
    flex-direction: column;
    text-align: center;
    position: relative;
}

.loader-component{
    margin-top: 5rem;
}

.container-profile__box{
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    h1{
        color: #50545F;
        font-family: PolySans-Slim;
        font-size: 36px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
        margin: 130px 0 25px 0;
    }

    .container-profile__box-steps{
        display: flex;
        flex-wrap: wrap;
        padding: 0 1rem 0 1rem;
        margin-top: 28px;
        gap: 30px;

        @media (max-width: 1200px) {
            justify-content: center;
        }

        @media (max-width: 450px) {
            gap: 12px;
        }

        & > button{
            display: inline-block;
            width: fit-content;
            color: #b2b3b3;
            font-family: "PolySans-Slim";
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 20px;
            text-transform: uppercase;
            border: none;
            background-color:transparent;
            display: flex;
            height: 40px;
            padding: 0px 20px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 20px;
            border: 2px solid #b2b3b3;
            transition: all 100ms ease;

            @media (max-width: 768px) {
                justify-content: center;
                padding: 0px 8px;
            }

            &:hover{
                color: #646464;
                border-color: #646464;
            }

            &:disabled{
                &:hover{
                    color: #b2b3b3;
                    border-color: #b2b3b3;             
                }
            }
        }

        .container-profile__box-steps--active{
            color: #646464;
            border-color: #646464;
        }
    }
}

.loading-element{
    margin-top: 75px;
}