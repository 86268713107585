.image-placeholder {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #f3f3f3;

		p {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 400px;
			font-size: 22px;
		}
	}