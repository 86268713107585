@import "../../../shared/style/Base";
.no-collection {
  display: flex;
  &.no-image{
    justify-content: center;
    .container-right{
      display: flex;
      flex-direction: column;
      align-items: center;
      .box{
        left: 0;
        top: 0;
        &::before {
          display: none;
        }
        p{
          text-align: center;
        }
      }
      .container-success{
        top: 0;
        left: 0;
      }
    }
  }
  .container-image{
    img{
      width: auto;
      max-width: 433px;
      height: 100%;
      max-height: 552px;
    }
  }
  .container-right{
    display: block;
    .box{
      background-color: $FONT_COLOR;
      padding: 30px;
      position: relative;
      left: -79px;
      top: 15px;
      width: 498px;
      &::before{
        content: "";
        width: 79px;
        height: 100%;
        border-left: none;
        position: absolute;
        top: 0;
        left: 0;
        box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.5);
        z-index: 2;
      }
      p{
        font-family: $FONT-POLY;
        color: #282826;
        font-size: 28px;
        &:first-child{
          margin-bottom: 15px;
        }
        &.text{
          color: #777777;
          font-size: 18px;
        }
      }
    }
    .container-form{
      display: flex;
      flex-direction: column;
      gap: 18px;
      position: relative;
      top: 15px;
      width: 360px;
      left: 30px;
      div{
        display: flex;
        flex-direction: column;
        label{
          font-family: $FONT-POLY;
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 5px;
        }
        input, textarea{
          border-radius: 5px;
          border: 1px solid #d4d4d4;
          padding: 12px;
        }
        textarea::placeholder{
          font-size: 16px;
        }
        .error{
          color: rgb(231, 83, 38);
          font-family: $FONT-POLY;
          font-size: 14px;
          img{
            width: 15px;
          }
        }
        .containeFeedback{
          padding-top: 5px;
          height: 70px !important;
          padding-bottom: 5px;
        }
      }
      .btn-send{
        align-self: flex-end !important;
      }
    }
    .container-success{
      display: none;
      flex-direction: column;
      align-items: center;
      position: relative;
      top: 60px;
      left: 30px;
      width: 360px;
      p{
        margin-top: 10px;
        font-family: $FONT-POLY;
        color: #707070;
        font-size: 16px;
        text-align: center;
      }
      button{
        margin-top: 60px;
        width: 241px;
        font-size: 14px;
      }
    }
  }
  @media(max-width:768px){
    flex-direction: column;
    .container-image{
      display: flex;
      justify-content: center;
      img{
        width: 80%;
        object-fit: contain;
      }
    }
    .container-right{
      .box{
        width: 90%;
        margin: auto;
        left: auto;
        // top: -90px;
        &::before {
          content: "";
          width: 100%;
          height: 100%;
          border-left: none;
          position: absolute;
          top: 0;
          left: 0;
          box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.5);
          z-index: 2;
        }
        p {
          font-size: 24px;
          &.text {
            font-size: 16px;
          }
        }
      }
      .container-form {
        top: 30px;
        left: 0;
        margin: auto;
      }
      .container-success {
        top: -20px;
        left: 0;
        margin: auto;
      }
    }
  }
}
a.back{
  width: 100%;
  max-width: 1200px;
  margin-top: 3rem;
  color: #444444;
  @media(max-width: 768px){
    width: 90%;
  }

  & span {
    border-bottom: 2px solid #444444;

    &:hover {
      color: lighten(#444444, 30%);
      border-bottom: 2px solid lighten(#444444, 30%);
    }
  }
}

.select-collection {
  padding-bottom: 150px;
  display: flex;
  justify-content: center;
  max-width: 900px;
  flex-wrap: wrap;
  margin: 0 auto;
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
  .collection-box {
    width: 200px;
    margin: 10px 10px;
    padding-bottom: 22.5px;
    border: 1px solid #ccc;
    text-align: center;
    cursor: pointer;
    @media (max-width: 500px) {
      margin-bottom: 20px;
    }

    .slider-wrapper {
      width: 200px;

      .image-wrapper {
        height: 300px;
        width: 200px;
        padding-bottom: 5px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .swiper-pagination {
        color: #666;
        @extend %Montserrat;

        font-size: 13px;
        font-weight: 400;
      }
      .swiper-button-disabled {
        opacity: 0;
      }
      .swiper-button-next {
        background-image: url("../images/right_arrow.png");
      }
      .swiper-button-prev {
        background-image: url("../images/left_arrow.png");
      }
      .swiper-button-next,
      .swiper-button-prev {
        top: auto;
        bottom: 14px;
        background-size: 24px 9px;
        width: 24px;
        height: 9px;
      }
    }
    .collection-name {
      margin-top: 10px;
      color: #666;
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;

      select {
        padding: 3px;
        margin-top: 10px;
        border: 1px solid #c0c0c0;
      }

      .name-container {
        height: 30px;
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .button-container {
        display: flex;
        justify-content: center;
        a {
          letter-spacing: 2px;
        }
        button {
          margin-top: 15px;
        }
      }
    }
    .button {
      line-height: 28px;
      height: 28px;
      margin-top: 15px;
    }
  }
}

.backgroundSelectPricelist {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 1000;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modalSelectPricelist {
  background-color: white;
  border-radius: 0.625rem;
  max-width: 22.5rem;
  width: 100%;
  margin: 0.3rem;
}
.modalSelectPricelist-header {
  padding: 0.3rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 1.063rem;
  border-bottom: 1px solid #cccccc;

  button {
    background-color: transparent;
    width: fit-content;
  }
}
.modalSelectPricelist-body {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  p {
    font-size: 0.9rem;
    color: #ee3131;
    font-weight: 500;
  }

  select {
    width: 100%;
    outline: none;
    padding: 0.5rem;
    border: 1px solid #cccccc;
    border-radius: 0.313rem;
  }
  div {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button {
      height: fit-content;
      width: fit-content;
      padding: 0.5rem 1rem;
    }

    button.disabled{
      background-color: rgba(0, 0, 0, 0.45);
    }
  }
}
